// import { getDefaultState } from './state'

const CHANGE_LANGUAGE = (state, payload) => {
  state.language = payload
}

const SET_PANORAMA_LINKS = (state, payload) => {
  state.panoramaLinks = payload
}

const SET_INTERFACE_TEXTS = (state, payload) => {
  state.interfaceTexsts = payload
}

const SET_PANORAMA_INFOSPOTS = (state, payload) => {
  state.panoramaInfospots = payload
}

const SET_PANORAMA_STORY_ARROWS = (state, payload) => {
  state.panoramaStoryArrows = payload
}

const SET_OPTIONS_STEPS = (state, payload) => {
  state.optionsMenu = payload
}

const SET_DIAGNOSE_STEPS = (state, payload) => {
  state.diagnoseSteps = payload
}

const PRELOAD_FINISHED = state => {
  state.preLoaded = true
}

const SHOW_TUTORIAL = state => {
  state.showTutorial = true
  state.tutorialShownBefore = true
}

const ACTIVE_PANORAMA = (state, payload) => {
  state.activePanorama = payload
  if (state.showTutorial) {
    state.showTutorial = false
  }
}

const STORY_MODE = (state, payload) => {
  state.storyMode = payload
}

const SHOW_DIALOG_TO_START_MEASUREMENT = (state, payload) => {
  state.showDialogToStartMeasurement = payload
}

const DIALOG_WELCOME = (state, payload) => {
  state.screenWelcome = payload
  state.exploreMode = !payload
}

const DIALOG_TALK = (state, payload) => {
  state.screenTalk = payload
  state.exploreMode = !payload
}

const BACK_TO_DIALOG_TALK = (state, payload) => {
  state.backToDialogTalk = payload
}

const DIALOG_OPTIONS = (state, payload) => {
  state.screenOptions = payload[0]
  state.exploreMode = !payload[0]
  state.optionsStep = payload[1]
}

const DEAD_END_POPUP = (state, payload) => {
  state.deadEndPopup = !state.deadEndPopup
  if (payload) {
    state.deadEndPopupData = payload
  }
}

const OPEN_LINK = (state, payload) => {
  state.openLink = payload
}

const VIDEO_POPUP = (state, payload) => {
  state.videoPopup = payload[0]
  if (payload[1]) {
    state.videoSrc = payload[1]
  }
}

const FORM_POPUP = (state, payload) => {
  if (payload) {
    state.formPopup = true
    state.formPopupType = payload
  } else {
    state.formPopup = false
  }
}

const ACTIVE_DIAGNOSE = (state, payload) => {
  state.activeDiagnose = payload
}

const PREVIOUS_OPTIONS = (state, payload) => {
  state.previousOptions = payload
}

const PREVIOUS_STEP = (state, payload) => {
  state.previousStep = payload
}

const TALK_STEP_SAVE = (state, payload) => {
  state.talkStepSaved = payload
}

const TALK_STEP_CONTINUE = (state, payload) => {
  state.talkStepContinue = payload
}

const SAVE_LAST_DIAGNOSE = (state, payload) => { //so BACK BUTTON knows where to return
  state.previousDiagnoses.push({
    diagnose: payload[0],
    panorama: payload[1]
  })
}

const DELETE_LAST_DIAGNOSE = state => { //When you use BACK BUTTON to go to previous diagnose
  state.previousDiagnoses.pop()
}

const SOLVED_DIAGNOSE = (state, payload) => {
  state.diagnose[payload].complete = true
  if (payload === 1) {
    state.diagnose[5].complete = true
  }

  // if ONE flow is complete we don't circle back when we complete the SECOND flow
  if (state.diagnose[1].complete && state.diagnose[2].complete && state.diagnose[3].complete && state.diagnose[4].complete && state.diagnose[5].complete) {
    state.levelComplete = true
  } else if (state.diagnose[1].complete && state.diagnose[4].complete) {
    state.flowOneComplete = true
  }
}

// Last step, check if all measurements have been completed
const CHECK_ALL_DIAGNOSES = state => {
  let complete = true
  let notCompleted = ''
  let endgameCheck =  state.interfaceTexsts.endgameCheck

  for (let measurement of state.diagnose) {
    if (measurement.complete === false) {
      complete = false
      if (notCompleted.length === 0) {
        notCompleted += measurement.name
      } else {
        notCompleted += `, ${measurement.name}`
      }
    }
  }
  state.completedAllMeasurements = complete
  if (complete) {
    state.optionsMenu.push(
      {
        name: 'Endgame',
        checkLevel: true,
        title: endgameCheck.diagnoses_complete.title,
        options: [
          {
            txt: endgameCheck.diagnoses_complete.option_one,
            link: 'inquiry'
          },
          {
            txt: endgameCheck.diagnoses_complete.option_two,
            link: 'reset'
          },
          {
            txt: endgameCheck.diagnoses_complete.option_three,
            link: 'backFromEndgame',
            show: true
          }
        ],
        backBtn: 6
      }
    )
  } else {
    state.optionsMenu.push(
      {
        name: 'Endgame',
        checkLevel: true,
        title: endgameCheck.diagnoses_not_complete.title_part_one + notCompleted + endgameCheck.diagnoses_not_complete.title_part_two,
        options: [
          {
            txt: endgameCheck.diagnoses_not_complete.option_one,
            link: 'inquiry'
          },
          {
            txt: endgameCheck.diagnoses_not_complete.option_two,
            link: 'reset'
          },
          {
            txt: endgameCheck.diagnoses_not_complete.option_three,
            link: 'backFromEndgame',
            show: true
          }
        ],
        backBtn: 6
      }
    )
  }
}

const DELETE_LAST_STEP = state => { // if not all steps are complete and user goes back to complete them, then this is needed to check again when he comes back to the last step and give an apropriate dialog from CHECK_ALL_DIAGNOSES mutation
  state.optionsMenu.pop()
}

const EXPLORE = state => {
  state.screenWelcome = false
  state.screenTalk = false
  state.screenOptions = false
  state.deadEndPopup = false
  state.exploreMode = true
}

const UPDATE_USER_NAME = (state, name) => {
  state.username = name
}

const SHOW_RIGHT_MENU = (state, bool) => {
  state.rightDrawer = bool
}

const SHOW_LEFT_MENU = (state, bool) => {
  state.leftDrawer = bool
}

export default {
  CHANGE_LANGUAGE,
  SHOW_RIGHT_MENU,
  SHOW_LEFT_MENU,
  SET_INTERFACE_TEXTS,
  SET_PANORAMA_LINKS,
  SET_PANORAMA_INFOSPOTS,
  SET_PANORAMA_STORY_ARROWS,
  SET_OPTIONS_STEPS,
  SET_DIAGNOSE_STEPS,
  PRELOAD_FINISHED,
  SHOW_TUTORIAL,
  ACTIVE_PANORAMA,
  STORY_MODE,
  SHOW_DIALOG_TO_START_MEASUREMENT,
  DIALOG_TALK,
  BACK_TO_DIALOG_TALK,
  DIALOG_WELCOME,
  DIALOG_OPTIONS,
  DEAD_END_POPUP,
  OPEN_LINK,
  VIDEO_POPUP,
  FORM_POPUP,
  ACTIVE_DIAGNOSE,
  PREVIOUS_OPTIONS,
  PREVIOUS_STEP,
  TALK_STEP_SAVE,
  TALK_STEP_CONTINUE,
  SAVE_LAST_DIAGNOSE,
  DELETE_LAST_DIAGNOSE,
  SOLVED_DIAGNOSE,
  CHECK_ALL_DIAGNOSES,
  DELETE_LAST_STEP,
  EXPLORE,
  UPDATE_USER_NAME
}
