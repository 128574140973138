export default {
  mounted () {
    this.analythicsEvents()
  },
  methods: {
    analythicsEvents () {
      // SimpleAnalytics Events
      const analyticsEventsPopups = document.getElementsByClassName('analytics-event')
      // console.log('analyticsEventsPopups: ', analyticsEventsPopups)

      for (const analyticsEvent of analyticsEventsPopups) {
        analyticsEvent.addEventListener('click', (e) => {
          // console.log('Analytics Event: ', e.target.id)
          if (this.saEvent) {
            this.saEvent('button-click', { button_id: e.target.id })
          }
        })
      }
    }
  }
}