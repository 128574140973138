<template>
  <v-container fluid class="home" ref="panoContainer">
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  data() {
    return {
      icons: {
        sound: require('@/assets/icons/VC_icon_sound.png'),
        soundOff: require('@/assets/icons/VC_icon_sound-off.png'),
        play: require('@/assets/icons/VC_icon_play.png'),
        click: require('@/assets/icons/VC_icon_click.png'),
        read: require('@/assets/icons/VC_icon_read.png')
      },
      images: [
        {
          img: require('@/assets/panoramas/Virtual-Clinic_ENTRANCE.jpg'),
          enterVector: [10, -2, 0]
        },
        {
          img: require('@/assets/panoramas/Virtual-Clinic_TABLE.jpg'),
          enterVector: [5, -2, -13]
        },
        {
          img: require('@/assets/panoramas/Virtual-Clinic_OFFICE.jpg'),
          enterVector: [8, -2, -3]
        },
        {
          img: require('@/assets/panoramas/Virtual-Clinic_BED.jpg'),
          enterVector: [10, -1, -4]
        }
      ],
      panoramaObjects: [
      	{}, {}, {}, {}
      ],
      panoLinksArrowObjects: [
        {}, {}, {}, {}
      ],
      panoInfospotsObjects: [
        {}, {}, {}, {}
      ],
      viewer: {},
      changePanorama: 0,
      timeouts: {
        panoramaLeave: null,
      },
      intervals: {  
        setCameraFov: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'language',
      'music',
      'preLoaded',
      'panoramaLinks',
      'panoramaInfospots',
      'panoramaStoryArrows',
      'storyMode',
      'exploreMode',
      'activePanorama',
      'activeDiagnose',
      'previousDiagnoses',
      'showDialogToStartMeasurement'
    ])
  },
  watch: {
    language (val) {
      if (val !== '') {
        this.preLoad()
      }
    },
    music () {
      this.toggleMusicIcons()
    },
    changePanorama (val) {
      this.$store.commit('SHOW_DIALOG_TO_START_MEASUREMENT', false)
      this.$store.commit('ACTIVE_PANORAMA', val)
    },
    activePanorama (value) {
      this.goToPanorama(value, this.showDialogToStartMeasurement)
      this.toggleArrowsAndSpots()
    },
    activeDiagnose (value) {
      this.panoramaArrows()
      this.storySelectedArrows(value)
    },
    storyMode (val) {
      this.toggleArrowsAndSpots()
      if (val && this.previousDiagnoses.length > 0) {
        this.storySelectedArrows(this.previousDiagnoses[this.previousDiagnoses.length - 1].diagnose)
      }
    },
    /**
     * Mouse 'grabs' the screen if we enable controls whithout timeout.
     * Off, when overlays are open, so we can use keyboard for those steps and don't move view.
     */
    exploreMode (value) {
      const tm = setTimeout(() => {
        if (value) {
          this.viewer.controls[0].enabled = true
        } else {
          this.viewer.controls[0].enabled = false
        }
        clearTimeout(tm)
      }, 200)
    }
  },
  methods: {
    toggleMusicIcons () {
      this.panoInfospotsObjects[0]['music-on'].visible = !this.music
      this.panoInfospotsObjects[0]['music-off'].visible = this.music
    },
    preLoad (count = 0) {
      if (count < this.images.length) {
        PANOLENS.ImageLoader.load(
          // resource URL
          this.images[count].img,

          // onLoad callback
          image => {
            if(image.src) {
              this.preLoad(count+1)
            }
          }
        )
      } else {
        this.createPanoramas()
        this.createViewer()
        this.addPanoramaLinks()
      }
    },
    createPanorama(index, imageObject) {
    	this.panoramaObjects[index] = new PANOLENS.ImagePanorama(imageObject.img);

      this.panoramaObjects[index].addEventListener('enter-fade-start', () => {
        this.viewer.tweenControlCenter(new THREE.Vector3(imageObject.enterVector[0], imageObject.enterVector[1], imageObject.enterVector[2]), 0);
        this.changePanorama = index
        this.panoramaEnterAnimation()
      });
    },
    createPanoramas() {
      this.images.forEach((imageObject, index) => {
        this.createPanorama(index, imageObject)
      });
    },
    createViewer () {
      let viewer;

      // https://pchen66.github.io/panolens.js/docs/Viewer.html
      viewer = new PANOLENS.Viewer(
        {
          container:          this.$refs['panoContainer'], // choose the element to put viewer - default is body
          controlBar:         false, // remove controls in the bottom right corner (Settings & full screen)
          autoHideInfospot:   false, // when clicking in panorama, hide infospots,
          horizontalView:     false,   // allow only horizontal view control
          cameraFov:          60,
          output:             'console'
          //initialLookAt:      new THREE.Vector3(10, -1, 0)
        }
      );

      viewer.control.maxFov = 80;
      viewer.control.minFov = 40;

      for (const panorama of this.panoramaObjects) {
      	viewer.add( panorama );
      }

      this.viewer = viewer
    },
    goToPanorama(index = 0, showDialogToStartMeasurement = false) {
      // https://pchen66.github.io/panolens.js/docs/Panorama.html
      this.viewer.setPanorama(this.panoramaObjects[index]);
      if (showDialogToStartMeasurement) {
        this.$store.dispatch('AUTO_OPEN_DIAGNOSE')
      }
    },
    panoramaLeaveAnimation (id) {
      clearTimeout(this.timeouts.panoramaLeave)
      this.timeouts.panoramaLeave = setTimeout(() => {
        clearInterval(this.intervals.setCameraFov)
        this.intervals.setCameraFov = setInterval(() => {
            if (this.viewer.camera.fov > 10) {
              let fovCounter = this.viewer.camera.fov - 1
              this.viewer.setCameraFov(fovCounter)
            } else {
            this.viewer.setPanorama(this.panoramaObjects[id]);
            clearInterval(this.intervals.setCameraFov)
          }
        }, 10)
      }, 200)
    },
    panoramaEnterAnimation () {
      clearInterval(this.intervals.setCameraFov)
      this.intervals.setCameraFov = setInterval(() => {
        if (this.viewer.camera.fov < 60) {
          let fovCounter = this.viewer.camera.fov + 3
          this.viewer.setCameraFov(fovCounter)
        } else {
          clearInterval(this.intervals.setCameraFov)
        }
      }, 10)
    },
    addPanoramaLinks () {
      let allLinks = 0;
      let loadedLinks = 0;
      this.panoramaLinks.forEach((panoLinks, index) => {
        allLinks += panoLinks.length

        panoLinks.forEach(linkObj => {
          let textureLoader = new THREE.TextureLoader();

          textureLoader.load(this.panoramaStoryArrows[linkObj.type], () => {
            let arrowLink = new PANOLENS.Infospot( linkObj.size, this.panoramaStoryArrows[linkObj.type] );
            arrowLink.position.set( ...linkObj.positionVector );
            this.panoramaObjects[index].add(arrowLink);

            arrowLink.addEventListener( 'click', () => {
              this.viewer.tweenControlCenter( new THREE.Vector3(...linkObj.onClickVector), 0 );
              this.panoramaLeaveAnimation(linkObj.panoToIndex)
              if (this.saEvent) {
                this.saEvent('arrow-click')
              }
            });

            this.panoLinksArrowObjects[index][linkObj.id] = arrowLink

            loadedLinks += 1
            if (loadedLinks === allLinks) {
              this.addPanoramaInfospots()
              this.panoramaArrows()
            }
          });
        })
      })
    },
    addPanoramaInfospots () {
      let allInfospots = 0;
      let loadedInfospots = 0;
      this.panoramaInfospots.forEach((panoInfospot, index) => {
        allInfospots += panoInfospot.length

        panoInfospot.forEach(infospotObj => {
          let textureLoader = new THREE.TextureLoader();

          textureLoader.load(this.icons[infospotObj.type], () => {
            let infospotLink = new PANOLENS.Infospot( infospotObj.size, this.icons[infospotObj.type] );
            infospotLink.position.set( ...infospotObj.positionVector );
            this.panoramaObjects[index].add(infospotLink);

            infospotLink.addEventListener( 'click', () => {
              if (infospotObj.contentType === 'music') {
                this.$store.commit('TOGGLE_MUSIC')
              } else if (infospotObj.contentType === 'link') {
                this.$store.commit('DEAD_END_POPUP', infospotObj.popup)
              } else if (infospotObj.contentType === 'story') {
                this.$store.commit('DIALOG_TALK', true)
              } else if (infospotObj.contentType === 'video') {
                this.$store.commit('VIDEO_POPUP', [true, infospotObj.url])
              }
              if (this.saEvent) {
                this.saEvent('infospot-click', { infospot_type: infospotObj.contentType })
              }
            });

            this.panoInfospotsObjects[index][infospotObj.id] = infospotLink

            loadedInfospots += 1
            if (loadedInfospots === allInfospots) {
              this.$store.commit('PRELOAD_FINISHED')
              this.toggleMusicIcons()
              this.panorama1Spots(false)
            }
          });
        })
      })
    },
    /**
     * Hide and Show Arrows 
     */ 
    toggleArrowsAndSpots () {
      if (this.storyMode) {
        this.panoramaArrows (this.activePanorama, false, false, false)
        if (this.activePanorama === 0) {
          this.panorama1Spots(false)
        } else if (this.activePanorama === 3) {
          if (this.activeDiagnose === 1) {
            this.panorama4Spots(true, false, false) //show ABI btn
          } else if (this.activeDiagnose === 2) {
            this.viewer.tweenControlCenter( new THREE.Vector3(10, -1, 3), 0 );
            this.panorama4Spots(false, false, true) //show ECG btn
          }
        } else if (this.activePanorama === 1) {
          if (this.activeDiagnose === 5) {
            this.viewer.tweenControlCenter( new THREE.Vector3(5, -2, -35), 0 );
            this.panorama2Spots(true, false) //show BP btn
          } else if (this.activeDiagnose === 3) {
            this.viewer.tweenControlCenter( new THREE.Vector3(-15, -4, -10), 0 );
            this.panorama2Spots(false, true) //show SPO btn
          }
        }
      } else {
        this.panoramaArrows(this.activePanorama, false, false, false, true)
        if (this.activePanorama === 0) {
          this.panorama1Spots(true)
        } else if (this.activePanorama === 1) {
          this.panorama2Spots(false, false, true)
        } else if (this.activePanorama === 3) {
          this.panorama4Spots(false, false, false, true)
        }
      }
    },
    storySelectedArrows (value) {
      if (value === 1 ) { //ABI --> is on panorama4
        if (this.activePanorama === 0) { //From panorama1
          this.viewer.tweenControlCenter( new THREE.Vector3(3500, -300, -200), 0 );
          const tm = setTimeout(() => {
            this.panoramaArrows(0, false, true, false)
            clearTimeout(tm)
          }, 400)
        } else if (this.activePanorama === 1) { //From panorama2
          this.viewer.tweenControlCenter( new THREE.Vector3(2000, -300, 1200), 0 );
          this.panorama2Spots(false, false)
          const tm = setTimeout(() => {
            this.panoramaArrows(1, true, false, false)
            clearTimeout(tm)
          }, 600)
        }
      } else if (value === 2) { //ECG --> is on panorama4
        if (this.activePanorama === 1) { //From panorama2
          this.viewer.tweenControlCenter( new THREE.Vector3(2000, -300, 1200), 0 );
          this.panorama2Spots(false, false)
          const tm = setTimeout(() => {
            this.panoramaArrows(1, true, false, false)
            clearTimeout(tm)
          }, 1000)
        }
        if (this.activePanorama === 3) { //Stay on panorama4
          this.viewer.tweenControlCenter( new THREE.Vector3(2500, 300, 750), 0 );
          const tm = setTimeout(() => {
            this.panorama4Spots(false, false, true)
            clearTimeout(tm)
          }, 200)
        }
      } else if (value === 3) { //SPO --> is on panorama2
        this.viewer.tweenControlCenter( new THREE.Vector3(-2200, -100, -2500), 0 );//From panorama4
        this.panorama4Spots(false, false, false)
        const tm = setTimeout(() => {
          this.panoramaArrows(3, false, false, true)
          clearTimeout(tm)
        }, 500)
      } else if (value === 4) { //TBI --> is on panorama4 
        const tm = setTimeout(() => { //Stay on panorama4
          this.viewer.tweenControlCenter( new THREE.Vector3( 2500, 0, -500 ), 0 );
          this.panorama4Spots(false, true, false) //show TBI btn
          clearTimeout(tm)
        }, 200)
      } else if (value === 5) { //BP --> is on panorama2
        this.viewer.tweenControlCenter( new THREE.Vector3(2500, -200, -1500), 0 );//From panorama1
        const tm = setTimeout(() => {
          this.panoramaArrows(0, true, false, false)
          clearTimeout(tm)
        }, 500)
      }
    },
    panoramaArrows (panoIndex = 0, left = false, middle = false, right = false, explore = false) {
      this.panoLinksArrowObjects[panoIndex]['left-story'].visible = left
      this.panoLinksArrowObjects[panoIndex]['middle-story'].visible = middle
      this.panoLinksArrowObjects[panoIndex]['right-story'].visible = right

      this.panoLinksArrowObjects[panoIndex]['left'].visible = explore
      this.panoLinksArrowObjects[panoIndex]['middle'].visible = explore
      this.panoLinksArrowObjects[panoIndex]['right'].visible = explore
    },
    // Hide and Show InfoSpots
    panorama1Spots (explore = false) {
      this.panoInfospotsObjects[0]['Testimonials'].visible = explore
    },
    panorama2Spots (bp = false, spo = false, explore = false) {
      this.panoInfospotsObjects[1]['BP'].visible = bp
      this.panoInfospotsObjects[1]['SPO'].visible = spo

      this.panoInfospotsObjects[1]['BP-explore'].visible = explore
      this.panoInfospotsObjects[1]['SPO-explore'].visible = explore
      this.panoInfospotsObjects[1]['Healthyarteries'].visible = explore
      this.panoInfospotsObjects[1]['BP-books'].visible = explore
      this.panoInfospotsObjects[1]['SPO-books'].visible = explore
      this.panoInfospotsObjects[1]['6MWT-books'].visible = explore
    },
    panorama4Spots (abi = false, tbi = false, ecg = false, explore = false) {
      this.panoInfospotsObjects[3]['ABI'].visible = abi
      this.panoInfospotsObjects[3]['TBI'].visible = tbi
      this.panoInfospotsObjects[3]['ECG'].visible = ecg

      this.panoInfospotsObjects[3]['ABI-explore'].visible = explore
      this.panoInfospotsObjects[3]['TBI-explore'].visible = explore
      this.panoInfospotsObjects[3]['ECG-explore'].visible = explore
      this.panoInfospotsObjects[3]['ABI-books'].visible = explore
      this.panoInfospotsObjects[3]['TBI-books'].visible = explore
      this.panoInfospotsObjects[3]['ECG-books'].visible = explore
    }
  }
}
</script>

<style lang="scss" scoped>

.home {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

</style>
