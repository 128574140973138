export const getDefaultState = () => {
  return {
    language: '',
    usernamePrefix: 'dr.',
    username: '',

    rightDrawer: false,
    leftDrawer: false,

    showTutorial: false,
    tutorialShownBefore: false,
    preLoaded: false,
    storyMode: true,
    exploreMode: true, // for turning on/off keyboard event listeners on panorama, so we can use them on overlays and panorama doesn't move underneath
    levelComplete: false,
    flowOneComplete: false,
    activePanorama: 0,
    showDialogToStartMeasurement: false, // when true, it will auto open diagnose popup from Home.vue when active panorama changes. Set to false, so that doesn't happen when exploring
    interfaceTexsts: {},
    panoramaLinks: [],
    panoramaInfospots: [],
    panoramaStoryArrows: {},

    // POPUPS
    screenWelcome: true,
    screenTalk: false,
    screenOptions: false,
    deadEndPopup: false,
    deadEndPopupData: {
      title: "",
      text: ``,
      buttonText: '',
      buttonAction: '',
    },
    videoPopup: false,
    videoSrc: "",
    formPopup: false,
    formPopupType: '',

    // OPTIONS MENU
    optionsStep: 0,
    optionsMenu: [],

    // DIAGNOSES
    activeDiagnose: 0, // current diagnose, to help with loading text to popups and to mark it as complete after going through steps
    previousDiagnoses: [], // save all diagnoses when changing them --> for back button functionality
    previousOptions: [], // save last optionsMenu, for back button in Endgame, because Endgame can be accessed from almost all steps of the story
    previousStep: 'Welcome', // for toggling story mode, so we know if we left off at dialogTalk or dialogOptions
    talkStepSaved: [],
    talkStepContinue: false,
    backToDialogTalk: false,
    completedAllMeasurements: false,
    diagnose: [
      {
        name: 'Welcome',
        complete: true
      },
      {
        name: 'ABI',
        complete: false
      },
      {
        name: 'ECG',
        complete: false
      },
      {
        name: 'SpO<sub>2</sub>',
        complete: false
      },
      {
        name: 'TBI',
        complete: false
      },
      {
        name: 'BP',
        complete: false
      },
      {
        name: 'Endgame',
        complete: true
      }
    ],
    diagnoseSteps: []
  }
}
