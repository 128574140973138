import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'
import VueGtm from 'vue-gtm'
import TWEEN from '@tweenjs/tween.js'
import SimpleAnalytics from "simple-analytics-vue"
import VueJsonLD from 'vue-jsonld'

// import * as THREE from 'three'
// import PANOLENS from 'panolens' TODO for optimization

import './assets/styles/main.scss'

// Import Cookie notice
import "vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css";
import VueCookieAcceptDecline from "vue-cookie-accept-decline";
Vue.component("vue-cookie-accept-decline", VueCookieAcceptDecline);

Vue.use(TWEEN)
// Vue.use(THREE)
// Vue.use(PANOLENS)

Vue.use(VueJsonLD)

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.use(SimpleAnalytics, { skip: process.env.NODE_ENV !== "production" });

if (process.env.NODE_ENV === 'production') {
  Vue.use(VueGtm, {
    id: 'GTM-5824WQ6',
    /*
    queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
      gtm_auth:'AB7cDEf3GHIjkl-MnOP8qr',
      gtm_preview:'env-4',
      gtm_cookies_win:'x'
    },
    */
    defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  })
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
