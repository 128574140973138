<template>
  <v-dialog
    id="deadEndPopup"
    ref="popup"
    v-model="deadEndPopup"
    tabindex="-1"
    persistent
    max-width="700px"
  >
    <v-card 
      rounded="xl"
      color="rgba(255, 255, 255, 0.85)"
      class="text-center pt-2 pb-5"
      @keyup.enter="btnActionHelper(deadEndPopupData.buttonAction)"
      @keyup.delete="close"
      @keyup.esc="close"
    >
      <v-card-title
        class="justify-center"
        v-html="deadEndPopupData.title !== '' ? deadEndPopupData.title : 'Notice'"
      />
      <v-card-text v-html="deadEndPopupData.text" />
      <v-card-actions class="justify-center">
        <v-btn
          light
          large
          class="mx-2"
          aria-label="Close popup"
          @click="close()"
        >
          {{ interfaceTexsts.back }}
        </v-btn>
        <v-btn
          v-if="deadEndPopupData.buttonAction !== ''"
          :id="deadEndPopupData.btnId+'-'+language"
          dark
          large
          class="analytics-event mx-2 pointer-events-for-analytics"
          aria-label="Open link"
          @click="btnActionHelper(deadEndPopupData.buttonAction)"
        >
          {{ deadEndPopupData.buttonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import mixins from '@/mixins.js'

export default {
  mixins: [mixins],
  computed: {
    ...mapGetters([
      'language',
      'deadEndPopup',
      'deadEndPopupData',
      'interfaceTexsts',
      'activeDiagnose',
      'activePanorama',
    ])
  },
  methods: {
    btnActionHelper (action) {
      this[action]()
    },
    close () {
      this.$store.commit('DEAD_END_POPUP')
    },
    endGame () {
      this.$store.commit('SAVE_LAST_DIAGNOSE', [this.activeDiagnose, this.activePanorama])
      this.$store.commit('EXPLORE')
      this.$store.dispatch('ENDGAME_DIALOG')
      this.$store.commit('DIALOG_TALK', true)
    },
    reset () { // Reload page for now --> TODO: Proper Reset
      this.$router.go(0);
    },
    openLink () {
      window.open(this.deadEndPopupData.url, '_blank,noopener');
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>

#deadEndPopup {
  z-index: 400;
}

.v-card__title {
  word-break: normal !important;
}

.keep-word-break {
  word-break: keep-all;
}

</style>
