<!-- Main navigation options overlay -->
<template>
  <v-dialog
    v-model="screenOptions"
    persistent
    fullscreen
    hide-overlay
    transition="fade-transition"
    open-delay="0.3s"
    class="py-5 screen"
  >
    <v-card 
      class="my-transparent d-flex justify-center align-center"
    >
      <div
        v-if="optionsMenuDataPresent"
        ref="option"
        tabindex="-1"
        class="no-outline pa-3 options-container"
        @keyup.down="loopOption('down')"
        @keyup.up="loopOption('up')"
        @keyup.enter="keyEnter"
        @keyup.delete="back"
        @keyup.esc="back"
      >
        <v-sheet
          color="rgba(0, 0, 0, 0.51)"
          class="white--text px-0 py-8 rounded-xl justify-center"
        >
          <h2
            class="pl-2 pr-8 mb-5 font-weight-medium"
            v-html="dialogTitle"
          />
          <v-container
            v-for="(option, indx) in data.options"
            :key="indx"
            class="pa-0"
          >
            <v-row
              v-if="showOption(indx)"
              :id="option.id+'-'+language"
              class="analytics-event pl-2 pr-8 py-1 ma-0 options-btn text-left"
              :class="{ optionActive: isActive(indx) }"
              aria-label="option"
              @click="btnLinkHelper(option.link)"
              @mouseover="mouseOver(indx)"
            >
              <v-icon class="white--text icon">
                mdi-chevron-right
              </v-icon>
              <v-col class="col-10 col-sm-11 px-0">
                <span v-html="option.txt" />
                <v-icon class="white--text ml-2">
                  mdi-gesture-double-tap
                </v-icon>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
        <div class="mt-3 d-flex justify-center">
          <v-btn
            dark
            large
            text
            class="rounded-lg back-btn"
            aria-label="back"
            @click="back"
          >
            <v-icon class="pb-1 mr-1 white--text icon">
              mdi-undo
            </v-icon>
            <span class="back-text text-uppercase">{{ interfaceTexsts.back }}</span>
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import mixins from '@/mixins.js'

export default {
  mixins: [mixins],
  data () {
    return {
      activeOption: 0
    }
  },
  computed: {
    ...mapGetters ([
      'language',
      'interfaceTexsts',
      'levelComplete',
      'flowOneComplete',
      'screenOptions',
      'optionsStep',
      'optionsMenu',
      'activePanorama',
      'activeDiagnose',
      'diagnoseSteps',
      'tutorialShownBefore',
      'completedAllMeasurements',
      'previousOptions'
    ]),
    data () {
      if (!this.optionsMenu || !this.optionsMenu[this.optionsStep]) {
        return {}
      }
      return this.optionsMenu[this.optionsStep]
    },
    dialogTitle () {
      if (!this.data) {
        return ''
      }
      return this.data.title
    },
    optionsMenuDataPresent () {
      return this.optionsMenu && Array.isArray(this.optionsMenu)
    }
  },
  watch: {
    screenOptions (value) {
      if (value ) {
        const tm = setTimeout(() => {
          this.$refs.option.focus()
          clearTimeout(tm)
        }, 500)
      } else {
        if (!this.tutorialShownBefore) {
          this.$store.commit('SHOW_TUTORIAL')
        }
        const time = setTimeout(() => {
          this.activeOption = 0
          clearTimeout(time)
        }, 500)
      }
    }
  },
  methods: {
    showOption (indx) {
      if ( indx === 0 && this.data.checkLevel && this.levelComplete && this.optionsStep !== 5) {
        return false
      } else if (indx === 2) {
        if (this.data.options[indx].show) {
          return true
        }
        return false
      }
      return true
    },
    isActive (indx) {
      return this.activeOption === indx
    },
    keyEnter () {
      this.btnLinkHelper(this.data.options[this.activeOption].link)
    },
    loopOption (direction = 'down') {
      if (this.data.checkLevel && this.levelComplete) { 
        return
      }

      const displayedOptions = this.data.options.filter((x,indx) => { 
        return this.showOption(indx)
      })

      const length = displayedOptions.length

      let newActiveOption = this.activeOption
      if (direction === 'down') {
        newActiveOption++
      } else {
        newActiveOption--
      }

      if (newActiveOption < 0) {
        newActiveOption = length - 1;
      } else if (newActiveOption > (length - 1) ) {
        newActiveOption = 0
      }

      this.activeOption = newActiveOption
    },  
    // we get the method name as string in link and call it like this (from store as buttonLink and secondButtonLink)
    btnLinkHelper (link) {
      this[link]()
    },
    back () {
      this.$store.commit('EXPLORE')
      this.$store.commit('ACTIVE_DIAGNOSE', this.data.backBtn)
      this.$store.dispatch('BACK_TO_DIALOG_TALK')
    },
    explore () {
      this.$store.commit('SAVE_LAST_DIAGNOSE', [this.activeDiagnose, this.activePanorama])
      this.$store.commit('PREVIOUS_STEP', 'Options')
      this.$store.commit('EXPLORE')
      const tm = setTimeout(() => {
        this.$store.commit('STORY_MODE', false)
        clearTimeout(tm)
      }, 1000)
    },
    deadEndPopup () {
      if (this.levelComplete && this.data.deadEndTextSuccess) {
        this.$store.commit('DEAD_END_POPUP', {
          title: this.interfaceTexsts.endgamePopupTitle,
          text: this.data.deadEndTextSuccess,
          buttonText: this.interfaceTexsts.end,
          buttonAction: 'endGame'
        })
      } else {
        this.$store.commit('DEAD_END_POPUP', {
          title: this.data.deadEndTitle,
          text: this.data.deadEndText,
          buttonText: this.interfaceTexsts.end,
          buttonAction: 'endGame'
        })
      }
    },
    outOfTimeText () {
      this.$store.commit('DEAD_END_POPUP', {
          title: this.data.outOfTimeTitle,
          text: this.data.outOfTimeText,
          buttonText: this.interfaceTexsts.yes,
          buttonAction: 'endGame'
        })
    },
    diagnoseABI () {
      this.$store.commit('SAVE_LAST_DIAGNOSE', [this.activeDiagnose, this.activePanorama])
      this.$store.commit('EXPLORE')
      const tm = setTimeout(() => {
        this.$store.commit('ACTIVE_DIAGNOSE', 1)
        clearTimeout(tm)
      }, 500)
    },
    diagnoseECG () {
      this.$store.commit('SAVE_LAST_DIAGNOSE', [this.activeDiagnose, this.activePanorama])
      this.$store.commit('EXPLORE')
      if (this.flowOneComplete) {
        const tm = setTimeout(() => {
          this.$store.commit('ACTIVE_DIAGNOSE', 2)
          clearTimeout(tm)
        }, 500)
      } else {
        const tm = setTimeout(() => {
          this.$store.commit('ACTIVE_DIAGNOSE', 5)
          clearTimeout(tm)
        }, 500)
      }
    },
    diagnoseSPO () {
      this.$store.commit('SAVE_LAST_DIAGNOSE', [this.activeDiagnose, this.activePanorama])
      this.$store.commit('EXPLORE')
      const tm = setTimeout(() => {
        this.$store.commit('ACTIVE_DIAGNOSE', 3)
        clearTimeout(tm)
      }, 500)
    },
    diagnoseTBI () {
      this.$store.commit('SAVE_LAST_DIAGNOSE', [this.activeDiagnose, this.activePanorama])
      this.$store.commit('EXPLORE')
      const tm = setTimeout(() => {
        this.$store.commit('ACTIVE_DIAGNOSE', 4)
        clearTimeout(tm)
      }, 500)
    },
    mouseOver (indx) {
      this.activeOption = indx
    },
    backFromEndgame () {
      this.$store.commit('EXPLORE')
      if (!this.completedAllMeasurements) {
        this.$store.commit('DIALOG_OPTIONS', [true, this.previousOptions])
        this.$store.commit('DELETE_LAST_STEP')
      } else {
        this.$store.commit('SAVE_LAST_DIAGNOSE', [this.activeDiagnose, this.activePanorama])
        this.$store.commit('STORY_MODE', false)
        this.$store.commit('PREVIOUS_STEP', 'Options')
      }
    },
    reset () {
      this.$store.dispatch('START_OVER')
    },
    inquiry () {
      this.$store.commit('FORM_POPUP', 'inquiry')
    }
  }
}
</script>

<style lang="scss" scoped>

.hide {
  visibility: hidden;
}

h2,
p {
  margin-left: 26px;
}

.full-height {
  height: 100vh;
  overflow: hidden;
}

a {
  color: white !important;
  text-decoration: underline;

  &:hover {
    color: #4F4E4B !important;
  }
}

.options-container {
  width: 700px;
  max-width: 730px;
}

</style>
