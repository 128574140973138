export default {
  storyArrows: {
    forward: require('@/assets/icons/arrows/VC_arrow_FORWARD.png'),
    leftSoft: require('@/assets/icons/arrows/VC_arrow_LEFT-soft.png'),
    leftSharp: require('@/assets/icons/arrows/VC_arrow_LEFT-sharp.png'),
    rightSoft: require('@/assets/icons/arrows/VC_arrow_RIGHT-soft.png'),
    rightSharp: require('@/assets/icons/arrows/VC_arrow_RIGHT-sharp.png'),

    forwardStory: require('@/assets/icons/arrows/VC_arrow-text_FORWARD_DEU.png'),
    leftSoftStory: require('@/assets/icons/arrows/VC_arrow-text_LEFT-soft_DEU.png'),
    leftSharpStory: require('@/assets/icons/arrows/VC_arrow-text_LEFT-sharp_DEU.png'),
    rightSoftStory: require('@/assets/icons/arrows/VC_arrow-text_RIGHT-soft_DEU.png'),
    rightSharpStory: require('@/assets/icons/arrows/VC_arrow-text_RIGHT-sharp_DEU.png')
  },
  infospots: [
    [ // panorama 1
      { // Music on
        id: 'music-on',
        positionVector: [1000, -900, 2900],
        contentType: 'music',
        type: 'sound',
        size: 150
      },
      { // Music off
        id: 'music-off',
        positionVector: [1000, -900, 2900],
        contentType: 'music',
        type: 'soundOff',
        size: 150
      },
      { // Testimonials
        id: 'Testimonials',
        positionVector: [1850, 850, 2900],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Erfahren Sie, was unsere Kunden über uns sagen.',
          text: `Dadurch wird ein neuer Browser-Reiter geöffnet.<br>Möchten Sie fortfahren?`,
          url: 'https://www.mesimedical.com/de/tag/dritte-meinung/',
          buttonText: 'JA',
          buttonAction: 'openLink',
          btnId: 'testimonials'
        },
        size: 300
      }
    ],
    
    [ // panorama 2
      { // BP
        id: 'BP',
        positionVector: [1400, -200, -3300],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612465818?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 350
      },
      { // SPO
        id: 'SPO',
        positionVector: [-2200, -1200, -450],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612466590?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 250
      },
      { // BP explore
        id: 'BP-explore',
        positionVector: [1400, -200, -3300],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/618862165?autoplay=1&title=0&byline=0&portrait=0',
        size: 350
      },
      { // SPO explore
        id: 'SPO-explore',
        positionVector: [-2200, -1200, -450],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/618869022?autoplay=1&title=0&byline=0&portrait=0',
        size: 250
      },
      { // Healthyarteries Poster
        id: 'Healthyarteries',
        positionVector: [-2200, 600, -600],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Erfahren Sie mehr über die Aufklärungskampagne Gesunde Arterien.',
          text: `Dadurch wird ein neuer Browser-Reiter geöffnet.<br>Möchten Sie fortfahren?`,
          url: 'https://healthyarteries.org/',
          buttonText: 'JA',
          buttonAction: 'openLink',
          btnId: 'healthyarteries'
        },
        size: 200
      },
      { // Books BP
        id: 'BP-books',
        positionVector: [900, -1800, -3300],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Greifen Sie auf Artikel zum Thema Blutdruck (BP).',
          text: `Dadurch wird ein neuer Browser-Reiter geöffnet.<br>Möchten Sie fortfahren?`,
          url: 'https://www.mesimedical.com/blood-pressure-and-other-measurements-in-one-location/',
          buttonText: 'JA',
          buttonAction: 'openLink',
          btnId: 'BP-book'
        },
        size: 350
      },
      { // Books SPO2
        id: 'SPO-books',
        positionVector: [1600, -2700, -3300],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Greifen Sie auf Artikel zum Thema Pulsoximetrie<span class="keep-word-break mx-1">(SpO<sub>2</sub>).</span>',
          text: `Dadurch wird ein neuer Browser-Reiter geöffnet.<br>Möchten Sie fortfahren?`,
          url: 'https://www.mesimedical.com/blood-oxygen-saturation-at-your-fingertips/',
          buttonText: 'JA',
          buttonAction: 'openLink',
          btnId: 'SPO-book'
        },
        size: 350
      },
      { // Books 6MWT
        id: '6MWT-books',
        positionVector: [650, -3600, -3300],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Greifen Sie auf Artikel zum Thema 6-minute Walk Test (6MWT).',
          text: `Dadurch wird ein neuer Browser-Reiter geöffnet.<br>Möchten Sie fortfahren?`,
          url: 'https://www.mesimedical.com/de/tag/6mwt/',
          buttonText: 'JA',
          buttonAction: 'openLink',
          btnId: '6MWT-book'
        },
        size: 350
      }
    ],

    [ // panorama 3
      { // mRecords explore
        id: 'm-records',
        positionVector: [5000, -810, -1664],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/528385791?autoplay=1&title=0&byline=0&portrait=0',
        size: 350
      },
      { // Table: Catalogue
        id: 'Catalogue',
        positionVector: [1800, -1500, -1800],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Haben Sie alle Informationen zum MESI mTABLET an einem Ort.',
          text: `Dadurch wird ein PDF-Dokument heruntergeladen.<br>Möchten Sie fortfahren?`,
          url: 'https://f.hubspotusercontent40.net/hubfs/5157245/MESI%20PDF%20Materials/MESI%20DE%20PDF%20Materials/MESI_mTABLET_catalogue_DE.pdf',
          buttonText: 'JA',
          buttonAction: 'openLink',
          btnId: 'Catalouge-book'
        },
        size: 200
      },
      { // Books CVD
        id: 'CVD-books',
        positionVector: [-1000, 300, -600],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Greifen Sie auf Artikel zum Thema Herz-Kreislauf-Erkrankung (HKE) zu.',
          text: `Dadurch wird ein neuer Browser-Reiter geöffnet.<br>Möchten Sie fortfahren?`,
          url: 'https://www.mesimedical.com/de/tag/herz-kreislauf-erkrankungen-hke/',
          buttonText: 'JA',
          buttonAction: 'openLink',
          btnId: 'CVD-book'
        },
        size: 100
      },
      { // Books PAD
        id: 'PAD-books',
        positionVector: [-1000, 0, -150],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Greifen Sie auf Artikel zum Thema Periphere arterielle Verschlusskrankheit (pAVK) zu.',
          text: `Dadurch wird ein neuer Browser-Reiter geöffnet.<br>Möchten Sie fortfahren?`,
          url: 'https://www.mesimedical.com/de/tag/periphere-arterielle-verschlusskrankheit-pavk/',
          buttonText: 'JA',
          buttonAction: 'openLink',
          btnId: 'PAD-book'
        },
        size: 100
      },
      { // Books CAD
        id: 'CAD-books',
        positionVector: [-1000, -300, -580],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Greifen Sie auf Artikel zum Thema koronare Herzkrankheit (KHK) zu.',
          text: `Dadurch wird ein neuer Browser-Reiter geöffnet.<br>Möchten Sie fortfahren?`,
          url: 'https://www.mesimedical.com/tag/coronary-artery-disease-cad/',
          buttonText: 'JA',
          buttonAction: 'openLink',
          btnId: 'CAD-book'
        },
        size: 100
      }
    ],

    [ // panorama 4
      { // ABI
        id: 'ABI',
        positionVector: [2500, 570, -800],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612465205?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 250
      },
      { // TBI
        id: 'TBI',
        positionVector: [2500, 530, -220],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612466784?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 250
      },
      { // ECG
        id: 'ECG',
        positionVector: [2500, 530, 750],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612466355?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 250
      },
      { // ABI explore
        id: 'ABI-explore',
        positionVector: [2500, 570, -800],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/618856960?autoplay=1&title=0&byline=0&portrait=0',
        size: 250
      },
      { // TBI explore
        id: 'TBI-explore',
        positionVector: [2500, 530, -220],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/618869972?autoplay=1&title=0&byline=0&portrait=0',
        size: 250
      },
      { // ECG explore
        id: 'ECG-explore',
        positionVector: [2500, 530, 750],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/618863297?autoplay=1&title=0&byline=0&portrait=0',
        size: 250
      },
      { // Trolley
        id: 'Trolley',
        positionVector: [-1360, -70, 2500],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Entdecken Sie den mobilen Speicher für Ihre drahtlosen Geräte.',
          text: `Dadurch wird ein PDF-Dokument heruntergeladen.<br>Möchten Sie fortfahren?`,
          url: 'https://f.hubspotusercontent40.net/hubfs/5157245/MESI%20PDF%20Materials/MESI%20DE%20PDF%20Materials/MESI_mTABLET_Trolley_2p_DE.pdf',
          buttonText: 'JA',
          buttonAction: 'openLink',
          btnId: 'trolley'
        },
        size: 250
      },
      { // Books ABI
        id: 'ABI-books',
        positionVector: [2500, -1100, -900],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Greifen Sie auf Artikel zum Thema Knöchel-Arm-Index (ABI) zu.',
          text: `Dadurch wird ein neuer Browser-Reiter geöffnet.<br>Möchten Sie fortfahren?`,
          url: 'https://www.mesimedical.com/de/tag/knoechel-arm-index-abi/',
          buttonText: 'JA',
          buttonAction: 'openLink',
          btnId: 'ABI-book'
        },
        size: 250
      },
      { // Books ECG
        id: 'ECG-books',
        positionVector: [2500, -1700, -630],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Greifen Sie auf Artikel zum Thema Elektrokardiographie (ECG) zu.',
          text: `Dadurch wird ein neuer Browser-Reiter geöffnet.<br>Möchten Sie fortfahren?`,
          url: 'https://www.mesimedical.com/tag/electrocardiogram/',
          buttonText: 'JA',
          buttonAction: 'openLink',
          btnId: 'ECG-book'
        },
        size: 250
      },
      { // Books TBI
        id: 'TBI-books',
        positionVector: [2500, -2200, -1100],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Greifen Sie auf Artikel zum Thema Zehen-Arm-Index (TBI) zu.',
          text: `Dadurch wird ein neuer Browser-Reiter geöffnet.<br>Möchten Sie fortfahren?`,
          url: 'https://www.mesimedical.com/de/tag/zehen-brachial-index-tbi/',
          buttonText: 'JA',
          buttonAction: 'openLink',
          btnId: 'TBI-book'
        },
        size: 250
      },
    ],
  ]
}