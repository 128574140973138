import Vue from 'vue'
import Vuex from 'vuex'
import { getDefaultState } from '@/store/state'
import actions from '@/store/actions'
import mutations from '@/store/mutations'
import getters from '@/store/getters'
import audio from '@/store/modules/audio.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: getDefaultState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
  modules: {
    audio
  }
})
