<template>
  <v-dialog
    id="formPopup"
    :value="formPopup"
    persistent
    fullscreen
    transition="fade-transition"
    open-delay="0.3s"
    hide-overlay
    class="py-5 screen"
  > 
    <v-card
      class="my-transparent"
    >
      <v-container
        fluid
        class="full-height pa-0 hide-overflow"
      >
        <v-row class="full-height no-outline justify-center ma-0  py-10">
          <v-col class="col-12 col-md-10 col-lg-8 mt-2 my-transparent rounded-xl form-container text-center">
            <div class="form-container px-5 py-2 auto-overflow">
              <h3>{{ formPopupType === 'inquiry' ? interfaceTexsts.formInquiryTitle : interfaceTexsts.formBookPresentationTitle }}</h3>
              <div
                id="form-import"
                ref="formref"
                class="container"
              />
            </div>
          </v-col>
          <v-col class="col-12 pa-0 d-flex justify-center back-container">
            <v-btn
              dark
              large
              text
              class="rounded-lg back-btn px-0"
              aria-label="close form"
              @click="close"
            >
              <v-icon class="pb-1 mr-1 white--text icon">
                mdi-undo
              </v-icon>
              <span class="back-text text-uppercase">{{ interfaceTexsts.back }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      formID: '13b1b373-031c-458e-981c-3c52676a1ad8'
    }
  },
  computed: {
    ...mapGetters([
      'language',
      'formPopup',
      'formPopupType',
      'interfaceTexsts'
    ])
  },
  watch: {
    formPopup (value) {
      if (value) {
        const tm = setTimeout(() => {
          this.selectForm()
          this.hsFormCreated = true
          clearTimeout(tm)
        }, 200)
      }
    }
  },
  methods: {
    close () {
      //Remove form
      let formImport = this.$refs.formref
      while (formImport.hasChildNodes()) {  
        formImport.removeChild(formImport.firstChild);
      }

      this.$store.commit('FORM_POPUP')
    },
    selectForm () {
      if (this.formPopupType === 'inquiry') {
        switch (this.language) {
          case 'SI':
            this.formID = '73906fe4-739d-42d4-bb45-3735b842e1e9'
            break;
          case 'DE':
            this.formID = 'f9ddfd36-1ba0-4d2b-8fef-17223cee9f6c'
            break;
          case 'FR':
            this.formID = 'ea86de32-708b-4308-b31d-135753703b55'
            break;
          case 'ES':
            this.formID = 'b0a625ea-4c80-4e38-98dc-ac5469e64527'
            break;
          case 'IT':
            this.formID = '3e623a85-522d-4059-9685-bbd49342290e'
            break;
        
          default:
            this.formID = '13b1b373-031c-458e-981c-3c52676a1ad8'
            break;
        }
      } else {
        switch (this.language) {
          case 'SI':
            this.formID = 'c35889ff-bd02-4915-8e81-d5d38f808851'
            break;
          case 'DE':
            this.formID = '9e147a41-280a-4155-9b2b-b16827fecdfe'
            break;
          case 'FR':
            this.formID = 'aaf45674-414f-4284-9122-bf9c88c11639'
            break;
          case 'ES':
            this.formID = '2971895e-4b0a-4cbd-990f-1f1d6630879d'
            break;
          case 'IT':
            this.formID = '29bb7a07-9cf0-49e7-b466-b8d93ab2042f'
            break;
        
          default:
            this.formID = 'b8d70ce2-3009-4990-ba84-07e273ce68cd'
            break;
        }
      }
      this.hsForm()
    },
    hsForm () {
      /**
     * Import Hubspot form
     */
      let formImport = this.$refs.formref
      let formCreate = document.createElement('script')
      let content = document.createTextNode(`
        hbspt.forms.create({
          region: "na1",
          portalId: "5157245",
          formId: "${this.formID}"
        });
      `);

      formCreate.appendChild(content)
      formImport.appendChild(formCreate)
    }
  }
}
</script>

<style lang="scss" scoped>

#formPopup {
  z-index: 700;
}

.full-height {
  height: 100vh;
}

.hide-overflow {
  overflow: hidden;
}

.auto-overflow {
  overflow-y: auto;
}

.form-container {
  height: 95%;
  max-width: 900px;
}

.back-container {
  height: fit-content;
}

</style>
