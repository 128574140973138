export default {
  storyArrows: {
    forward: require('@/assets/icons/arrows/VC_arrow_FORWARD.png'),
    leftSoft: require('@/assets/icons/arrows/VC_arrow_LEFT-soft.png'),
    leftSharp: require('@/assets/icons/arrows/VC_arrow_LEFT-sharp.png'),
    rightSoft: require('@/assets/icons/arrows/VC_arrow_RIGHT-soft.png'),
    rightSharp: require('@/assets/icons/arrows/VC_arrow_RIGHT-sharp.png'),

    forwardStory: require('@/assets/icons/arrows/VC_arrow-text_FORWARD_SLO.png'),
    leftSoftStory: require('@/assets/icons/arrows/VC_arrow-text_LEFT-soft_SLO.png'),
    leftSharpStory: require('@/assets/icons/arrows/VC_arrow-text_LEFT-sharp_SLO.png'),
    rightSoftStory: require('@/assets/icons/arrows/VC_arrow-text_RIGHT-soft_SLO.png'),
    rightSharpStory: require('@/assets/icons/arrows/VC_arrow-text_RIGHT-sharp_SLO.png')
  },
  infospots: [
    [ // panorama 1
      { // Music on
        id: 'music-on',
        positionVector: [1000, -900, 2900],
        contentType: 'music',
        type: 'sound',
        size: 150
      },
      { // Music off
        id: 'music-off',
        positionVector: [1000, -900, 2900],
        contentType: 'music',
        type: 'soundOff',
        size: 150
      },
      { // Testimonials
        id: 'Testimonials',
        positionVector: [1850, 850, 2900],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Preverite mnenje naših strank o nas.',
          text: `Odprl se bo nov zavihek brskalnika.<br>Ali želite nadaljevati?`,
          url: 'https://www.mesimedical.com/sl/tag/tretje-mnenje/',
          buttonText: 'DA',
          buttonAction: 'openLink',
          btnId: 'testimonials'
        },
        size: 300
      }
    ],
    
    [ // panorama 2
      { // BP
        id: 'BP',
        positionVector: [1400, -200, -3300],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612551775?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 350
      },
      { // SPO
        id: 'SPO',
        positionVector: [-2200, -1200, -450],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612552436?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 250
      },
      { // BP explore
        id: 'BP-explore',
        positionVector: [1400, -200, -3300],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/619718106?autoplay=1&title=0&byline=0&portrait=0',
        size: 350
      },
      { // SPO explore
        id: 'SPO-explore',
        positionVector: [-2200, -1200, -450],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/619719428?autoplay=1&title=0&byline=0&portrait=0',
        size: 250
      },
      { // Healthyarteries Poster
        id: 'Healthyarteries',
        positionVector: [-2200, 600, -600],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Pridobite več informacij o kampanji ozaveščanja Zdrave arterije.',
          text: `Odprl se bo nov zavihek brskalnika.<br>Ali želite nadaljevati?`,
          url: 'https://healthyarteries.org/',
          buttonText: 'DA',
          buttonAction: 'openLink',
          btnId: 'healthyarteries'
        },
        size: 200
      },
      { // Books BP
        id: 'BP-books',
        positionVector: [900, -1800, -3300],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Dostopajte do člankov, ki se nanašajo na Krvni Tlak (BP).',
          text: `Odprl se bo nov zavihek brskalnika.<br>Ali želite nadaljevati?`,
          url: 'https://www.mesimedical.com/sl/krvni-tlak-in-druga-merjenja-na-enem-mestu/',
          buttonText: 'DA',
          buttonAction: 'openLink',
          btnId: 'BP-book'
        },
        size: 350
      },
      { // Books SPO2
        id: 'SPO-books',
        positionVector: [1600, -2700, -3300],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Dostopajte do člankov, ki se nanašajo na Pulzno Oksimetrijo<span class="keep-word-break mx-1">(SpO<sub>2</sub>).</span>',
          text: `Odprl se bo nov zavihek brskalnika.<br>Ali želite nadaljevati?`,
          url: 'https://www.mesimedical.com/sl/nasicenost-krvi-s-kisikom-saturacija-na-dosegu-roke/',
          buttonText: 'DA',
          buttonAction: 'openLink',
          btnId: 'SPO-book'
        },
        size: 350
      },
      { // Books 6MWT
        id: '6MWT-books',
        positionVector: [650, -3600, -3300],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Dostopajte do člankov, ki se nanašajo na 6-minutni test hoje (6MWT).',
          text: `Odprl se bo nov zavihek brskalnika.<br>Ali želite nadaljevati?`,
          url: 'https://www.mesimedical.com/sl/tag/6mwt/',
          buttonText: 'DA',
          buttonAction: 'openLink',
          btnId: '6MWT-book'
        },
        size: 350
      }
    ],

    [ // panorama 3
      { // mRecords explore
        id: 'm-records',
        positionVector: [5000, -810, -1664],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/528385791?autoplay=1&title=0&byline=0&portrait=0',
        size: 350
      },
      { // Table: Catalogue
        id: 'Catalogue',
        positionVector: [1800, -1500, -1800],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Imejte vse informacije o MESI mTABLET na enem mestu.',
          text: `S tem boste prenesli dokument PDF.<br>Ali želite nadaljevati?`,
          url: 'https://f.hubspotusercontent40.net/hubfs/5157245/MESI%20PDF%20Materials/MESI%20SLO%20PDF%20materials/MESI_mTABLET_catalogue_SLO.pdf',
          buttonText: 'DA',
          buttonAction: 'openLink',
          btnId: 'Catalouge-book'
        },
        size: 200
      },
      { // Books CVD
        id: 'CVD-books',
        positionVector: [-1000, 300, -600],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Dostopajte do člankov, ki se nanašajo na Kardiovaskularne bolezni (KVB).',
          text: `Odprl se bo nov zavihek brskalnika.<br>Ali želite nadaljevati?`,
          url: 'https://www.mesimedical.com/sl/tag/kardiovaskularna-bolezen-cvd/',
          buttonText: 'DA',
          buttonAction: 'openLink',
          btnId: 'CVD-book'
        },
        size: 100
      },
      { // Books PAD
        id: 'PAD-books',
        positionVector: [-1000, 0, -150],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Dostopajte do člankov, ki se nanašajo na Periferno arterijsko bolezen (PAB).',
          text: `Odprl se bo nov zavihek brskalnika.<br>Ali želite nadaljevati?`,
          url: 'https://www.mesimedical.com/sl/tag/periferna-arterijska-bolezen-pab/',
          buttonText: 'DA',
          buttonAction: 'openLink',
          btnId: 'PAD-book'
        },
        size: 100
      },
      { // Books CAD
        id: 'CAD-books',
        positionVector: [-1000, -300, -580],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Dostopajte do člankov, ki se nanašajo na koronarno arterijsko bolezen (KAB).',
          text: `Odprl se bo nov zavihek brskalnika.<br>Ali želite nadaljevati?`,
          url: 'https://www.mesimedical.com/sl/tag/koronarna-arterijska-bolezen-kab/',
          buttonText: 'DA',
          buttonAction: 'openLink',
          btnId: 'CAD-book'
        },
        size: 100
      }
    ],

    [ // panorama 4
      { // ABI
        id: 'ABI',
        positionVector: [2500, 570, -800],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612551177?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 250
      },
      { // TBI
        id: 'TBI',
        positionVector: [2500, 530, -220],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612552591?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 250
      },
      { // ECG
        id: 'ECG',
        positionVector: [2500, 530, 750],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612551968?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 250
      },
      { // ABI explore
        id: 'ABI-explore',
        positionVector: [2500, 570, -800],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/619716254?autoplay=1&title=0&byline=0&portrait=0',
        size: 250
      },
      { // TBI explore
        id: 'TBI-explore',
        positionVector: [2500, 530, -220],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/619719617?autoplay=1&title=0&byline=0&portrait=0',
        size: 250
      },
      { // ECG explore
        id: 'ECG-explore',
        positionVector: [2500, 530, 750],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/619718290?autoplay=1&title=0&byline=0&portrait=0',
        size: 250
      },
      { // Trolley
        id: 'Trolley',
        positionVector: [-1360, -70, 2500],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Spoznajte mobilno shrambo za vaše brezžične naprave.',
          text: `S tem boste prenesli dokument PDF.<br>Ali želite nadaljevati?`,
          url: 'https://f.hubspotusercontent40.net/hubfs/5157245/MESI%20PDF%20Materials/MESI%20SLO%20PDF%20materials/MESI_mTABLET_Trolley_2p_SL.pdf',
          buttonText: 'DA',
          buttonAction: 'openLink',
          btnId: 'trolley'
        },
        size: 250
      },
      { // Books ABI
        id: 'ABI-books',
        positionVector: [2500, -1100, -900],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Dostopajte do člankov, ki se nanašajo na gleženjski indeks. Odprl se bo nov zavihek brskalnika.',
          text: `Odprl se bo nov zavihek brskalnika.<br>Ali želite nadaljevati?`,
          url: 'https://www.mesimedical.com/tag/ankle-brachial-index-abi/',
          buttonText: 'DA',
          buttonAction: 'openLink',
          btnId: 'ABI-book'
        },
        size: 250
      },
      { // Books ECG
        id: 'ECG-books',
        positionVector: [2500, -1700, -630],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Dostopajte do člankov, ki se nanašajo na  Electrocardiography (ECG).',
          text: `Odprl se bo nov zavihek brskalnika.<br>Ali želite nadaljevati?`,
          url: 'https://www.mesimedical.com/tag/electrocardiogram/',
          buttonText: 'DA',
          buttonAction: 'openLink',
          btnId: 'ECG-book'
        },
        size: 250
      },
      { // Books TBI
        id: 'TBI-books',
        positionVector: [2500, -2200, -1100],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Dostopajte do člankov, ki se nanašajo na  Toe-Brachial Index (TBI).',
          text: `Odprl se bo nov zavihek brskalnika.<br>Ali želite nadaljevati?`,
          url: 'https://www.mesimedical.com/sl/tag/indeks-palec-nadlaht/',
          buttonText: 'DA',
          buttonAction: 'openLink',
          btnId: 'TBI-book'
        },
        size: 250
      },
    ],
  ]
}