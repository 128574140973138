<!-- Transparent overlay for patient/doctor dialog -->
<template>
  <div>
    <v-dialog
      v-model="screenTalk"
      persistent
      fullscreen
      transition="fade-transition"
      open-delay="0.3s"
      hide-overlay
      class="py-5 screen"
    > 
      <v-card
        v-if="diagnoseStepsDataPresent"
        class="my-transparent"
      >
        <v-container
          fluid
          class="full-height pa-0 hide-overflow"
        >
          <v-row
            v-show="!showVideo"
            class="full-height no-outline justify-center ma-0"
            :class="!isMtabletStep ? 'align-center' : ''"
          >
            <DialogTalkDialogs
              v-show="!isMtabletStep"
              :step-data="data.steps[stepsCount]"
              :steps-count="stepsCount"
              :show-video="showVideo"
              @nextStep="nextStep"
              @backHelper="backHelper"
            />

            <MTablet
              v-if="isMtabletStep"
              :back-to-tablet="isMtabletStep"
              :step-data="data.steps[stepsCount]"
              :measurement-name="data.name"
              @backHelper="backHelper"
              @resetBackToTablet="resetBackToTablet"
              @videoStep="videoStep"
            />

            <v-col
              v-else-if="data.steps[stepsCount].image !== '' && !this.$vuetify.breakpoint.smAndDown"
              class="mt-sm-6 col-md-6 text-center col-lg-4 text-md-right"
              order-md="1"
            >
              <img
                :src="require('@/assets/images/' + data.steps[stepsCount].image)"
                class="img-fluid full-height"
                alt="patient"
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <videoPopup
      v-if="storyMode"
      @nextStep="nextStep"
      @closeVideo="closeVideo"
      @setBackToTablet="setBackToTablet"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DialogTalkDialogs from '@/components/DialogTalkDialogs'
import MTablet from '@/components/MTablet'
import VideoPopup from '@/components/VideoPopup'

export default {
  components: {
    DialogTalkDialogs, MTablet, VideoPopup
  },
  data () {
    return {
      stepsCount: 0,
      menuOptions: 0,
      showVideo: false,
      backToTablet: false
    }
  },
  computed: {
    ...mapGetters([
      'storyMode',
      'screenTalk',
      'activeDiagnose',
      'activePanorama',
      'diagnoseSteps',
      'previousDiagnoses',
      'previousOptions',
      'talkStepSaved',
      'talkStepContinue',
      'backToDialogTalk',
      'completedAllMeasurements'
    ]),
    isMtabletStep () {
      return this.data.steps[this.stepsCount].image === 'mTablet'
    },
    data () {
      if (!this.diagnoseSteps || !this.diagnoseSteps[this.activeDiagnose]) {
        return {}
      }
      return this.diagnoseSteps[this.activeDiagnose]
    },
    stepTitle () {
      if (!this.data) {
        return ''
      }
      return this.data.title
    },
    stepVideo () {
      if (!this.data) {
        return ''
      }
      return this.data.video
    },
    diagnoseStepsDataPresent () {
      return this.diagnoseSteps[this.activeDiagnose] && Array.isArray(this.diagnoseSteps)
    }
  },
  watch: {
    screenTalk (value) {
      if (!value) { //if someone closes popup before last step (can be done from navigation), we have to reset steps
        this.$store.commit('TALK_STEP_SAVE', [this.stepsCount, this.showVideo])
        const tm = setTimeout(() => {
          this.stepsCount = 0
          this.closeVideo()
          clearTimeout(tm)
        }, 500)
      }
    },
    backToDialogTalk (val) {
      if (val) {
        this.stepsCount = this.data.steps.length-1
      }
    },
    talkStepContinue (val) {
      if (val) {
        this.stepsCount = this.talkStepSaved[0]
        if (this.talkStepSaved[1]) {
          this.videoStep()
        }
      }
    }
  },
  methods: {
    videoStep () {
      this.$store.commit('VIDEO_POPUP', [true, this.stepVideo])
      this.showVideo = true
    },
    closeVideo () {
      this.showVideo = false
      this.$store.commit('VIDEO_POPUP', [false])
    },
    setBackToTablet () {
      this.backToTablet = true
    },
    resetBackToTablet () {
      this.backToTablet = false
    },
    nextStep () {
      if (this.activeDiagnose !== 5) { // BP is the only one with no seperate Options Menu. We skip opening optionMenu at BP and go directly to ECG.
        if (this.activeDiagnose === 6) {
          this.menuOptions =  5 // Endgame activeDiagnose is 6 but in options menu it is 5th TODO: change the order and put BP last since it doesn't have options menu --> Also check Minimap.vue in story method
        } else {
          this.$store.commit('PREVIOUS_OPTIONS', this.menuOptions)
          this.menuOptions =  this.activeDiagnose
        }
      }
      if (this.stepsCount >= this.data.steps.length-1) {
        this.$store.commit('DIALOG_TALK', false)

        if (this.activeDiagnose === 5) {
          this.$store.commit('SAVE_LAST_DIAGNOSE', [this.activeDiagnose, this.activePanorama])
          this.$store.commit('SOLVED_DIAGNOSE', this.activeDiagnose)
          this.$store.commit('ACTIVE_DIAGNOSE', 2)
        } else {
          this.$store.commit('SOLVED_DIAGNOSE', this.activeDiagnose)
          this.$store.commit('DIALOG_OPTIONS', [true, this.menuOptions])
        }
        const tm = setTimeout(() => {
          this.stepsCount = 0
          clearTimeout(tm)
        }, 500)

      } else {
        this.stepsCount++
      }
    },
    backFromEndgame () {
      this.$store.commit('EXPLORE')
      this.$store.commit('DIALOG_OPTIONS', [true, this.previousOptions])
      this.stepsCount = 0
      this.$store.commit('DELETE_LAST_STEP')
    },
    backHelper (link) {
      this[link]()
    },
    // Different BACK BUTTON methods
    welcome () {
      this.$store.commit('EXPLORE')
      this.$store.commit('DIALOG_WELCOME', true)
    },
    video () {
      this.videoStep()
      this.back()
    },
    options () {
      this.$store.commit('EXPLORE')
      this.$store.commit('ACTIVE_DIAGNOSE', this.previousDiagnoses[this.previousDiagnoses.length - 1].diagnose)
      this.$store.commit('ACTIVE_PANORAMA', this.previousDiagnoses[this.previousDiagnoses.length - 1].panorama)
      const tm = setTimeout(() => {
        if (this.previousDiagnoses[this.previousDiagnoses.length - 1].diagnose === 5) {
          this.$store.dispatch('BACK_TO_DIALOG_TALK')
          this.videoStep()
          this.$store.commit('DELETE_LAST_DIAGNOSE')
        } else {
          this.$store.commit('DIALOG_OPTIONS', [true, this.previousOptions])
          this.$store.commit('DELETE_LAST_DIAGNOSE')
        }
        clearTimeout(tm)
      }, 1500)
    },
    back () {
      this.stepsCount--
    }
  }
}
</script>

<style lang="scss" scoped>
.full-height {
  height: 100vh;
}

.hide-overflow {
  overflow: hidden;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

</style>
