<!-- Transparent overlay for patient/doctor dialog -->

<template>
  <v-btn 
    v-if="!preLoaded && language !== ''"
    :top="!isMobile"
    :right="!isMobile"
    :block="isMobile"
    :fixed="!isMobile"
    depressed
    :color="isMobile ? 'rgba(0,0,0,0.4)' : 'transparent'" 
    :class="[isMobile ? 'm-loader-mobile py-7 mb-5' : '']"
    class="m-loader"
    data-cy-loader
  > 
    <span class="d-inline-block mr-2 text-none bg--text">Loading</span> 
    <v-progress-circular 
      background-color="bg"
      color="bg" 
      height="10" 
      indeterminate
      class="my-3"
    />
  </v-btn>   
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Navbar',
  computed: {
    ...mapGetters([
      'language',
      'preLoaded'
    ]),
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  }
}
</script>

<style lang="scss">
.m-loader {
  pointer-events: none;
  z-index: 9999999;

  &.m-loader-mobile {
    text-align: center;
    top: 0 !important;
  }
}
</style>
