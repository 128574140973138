<template>
  <div class="video-ctas">
    <v-tooltip
      v-if="storyMode"
      top
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          large
          v-bind="attrs"
          class="mr-3 mb-3"
          aria-label="back"
          v-on="on"
          @click="closeBack()"
        >
          <v-icon class="pb-1 mr-1 white--text icon">
            mdi-undo
          </v-icon>
          <span class="back-text text-uppercase">{{ interfaceTexsts.back }}</span>
        </v-btn>
      </template>
      {{ interfaceTexsts.backTooltip }}
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          light
          large
          class="mb-3"
          aria-label="close video"
          v-bind="attrs"
          v-on="on"
          @click="closeNext()"
        >
          {{ storyMode ? interfaceTexsts.videoDoneCTA.storyText : interfaceTexsts.videoDoneCTA.exploreText }}
          <v-icon
            v-if="storyMode"
            right
          >
            mdi-information-outline
          </v-icon>
          <v-icon
            v-else
            right
          >
            mdi-close
          </v-icon>
        </v-btn>
      </template>
      {{ storyMode ? interfaceTexsts.videoDoneCTA.storyInfoText : interfaceTexsts.videoDoneCTA.exploreInfoText }}
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'storyMode',
      'interfaceTexsts'
    ])
  },
  methods: {
    closeNext () {
      if (this.storyMode) {
        this.$emit('storyVideoClose')
      } else {
        this.$store.commit('VIDEO_POPUP', [false])
      }
    },
    closeBack () {
      this.$emit('storyVideoCloseBack')
    }
  }
}
</script>

<style lang="scss" scoped>
.video-ctas {
  position: absolute;
  width: auto;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}
</style>