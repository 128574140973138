<!-- Transparent overlay for patient/doctor dialog -->

<template>
  <v-col
    class="pt-15 pb-0"
    cols="12"
  >
    <div class="back-btn-wrap text-center">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            large
            v-bind="attrs"
            class="mr-3 mb-3"
            aria-label="back"
            v-on="on"
            @click="backHelper(stepData.backBtn)"
          >
            <v-icon class="pb-1 mr-1 white--text icon">
              mdi-undo
            </v-icon>
            <span class="back-text text-uppercase">{{ interfaceTexsts.back }}</span>
          </v-btn>
        </template>
        {{ interfaceTexsts.backTooltip }}
      </v-tooltip>
    </div>
    <div class="tablet-container">
      <img
        :src="mTabletAppsImg"
        class="img-fluid m-tablet"
        alt="mTablet"
      >
      <mTabletButtonsDE
        v-if="language === 'DE'"
        class="m-tablet"
      />
      <mTabletButtonsSI
        v-else-if="language === 'SI'"
        class="m-tablet"
      />
      <mTabletButtonsFR
        v-else-if="language === 'FR'"
        class="m-tablet"
      />
      <mTabletButtonsES
        v-else-if="language === 'ES'"
        class="m-tablet"
      />
      <mTabletButtonsIT
        v-else-if="language === 'IT'"
        class="m-tablet"
      />
      <mTabletButtonsEN
        v-else
        class="m-tablet"
      />
    </div>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
import mTabletButtonsEN from '@/assets/images/mTablet/mTABLET_app_image-map_ENG.svg'
import mTabletButtonsDE from '@/assets/images/mTablet/mTABLET_app_image-map_DEU.svg'
import mTabletButtonsSI from '@/assets/images/mTablet/mTABLET_app_image-map_SLO.svg'
import mTabletButtonsFR from '@/assets/images/mTablet/mTABLET_app_image-map_FRA.svg'
import mTabletButtonsES from '@/assets/images/mTablet/mTABLET_app_image-map_ESP.svg'
import mTabletButtonsIT from '@/assets/images/mTablet/mTABLET_app_image-map_ITA.svg'

export default {
  components: {
    mTabletButtonsEN,
    mTabletButtonsDE,
    mTabletButtonsSI,
    mTabletButtonsFR,
    mTabletButtonsES,
    mTabletButtonsIT
  },
  props: {
    backToTablet: Boolean,
    stepData: {
      type: Object,
      default: () => {}
    },
    measurementName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      mTabletAppsImg: require('@/assets/images/mTablet/VC_mTABLET_apps_EN.png')
    }
  },
  computed: {
    ...mapGetters([
      'language',
      'activeDiagnose',
      'interfaceTexsts'
    ])
  },
  watch: {
    activeDiagnose () {
      this.activateTabletButton ()
    },
    backToTablet (val) {
      if (val) {
        this.activateTabletButton ()
      }
    }
  },
  mounted () {
    this.mTabletAppsImg = require('@/assets/images/mTablet/VC_mTABLET_apps_'+this.language+'.png')
    // console.log(this.language, 'CHOSEN IMAGE: ', this.mTabletAppsImg)
    this.$nextTick(() => {
      this.activateTabletButton ()
    })
  },
  methods: {
    activateTabletButton () {
      this.removeAllActiveClasses ()

      //console.log('activate: ', this.measurementName)
      let svgButton = document.getElementById(this.measurementName+'_app_icon');
      if(svgButton) {
        svgButton.classList.add('active');
        svgButton.addEventListener('click', () => {
          this.videoStep()
        });
      } else {
        //console.warn('COULD NOT activate ', this.measurementName)
      }
    },
    removeAllActiveClasses () {
      const activeElements = this.$el.getElementsByClassName('active')
      if (typeof activeElements !== 'undefined' && activeElements.length > 0) {
        //console.warn('REMOVE -active- class from all now')
        activeElements[0].classList.remove('active');
      }
    },
    backHelper (backBtn) {
      this.$emit('backHelper', backBtn)
    },
    videoStep () {
      if (this.saEvent) {
        this.saEvent('tablet-button-click')
      }
      this.$emit('resetBackToTablet')
      this.$emit('videoStep')
    }
  }
}
</script>

<style lang="scss" scoped>
.back-btn-wrap {
  position: absolute;
  z-index: 9999;
  width: 100%;
}

.tablet-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.m-tablet {
  .st0{fill:#FFFFFF;}
  .st1{fill:#929395;}
  .st2{fill:#1A1A1A;}

  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  .button {
    visibility: hidden;
    cursor: pointer;
    &.active {
      visibility: visible;
      @include animate_opacity();     
      //stroke: black;
      //stroke-width: 1;
    }
  }
}
</style>
