<template>
  <v-col
    ref="talk"
    class="talk-container col-md-6 col-lg-4"
    order-md="2"
    tabindex="-1"
    data-cy-dialog-talk
    @keyup.enter="stepData.btnVideo ? videoStep() : keyEnter()"
    @keyup.delete="backHelper(stepData.backBtn)"
    @keyup.esc="backHelper(stepData.backBtn)"
  >
    <v-row class="mx-2 mx-lg-0">
      <div
        class="py-3 white--text d-flex align-center"
        data-cy-dialog-speaker
      >
        <v-icon
          large
          class="mr-3"
          color="white"
        >
          mdi-account-voice
        </v-icon>
        <strong v-if="stepData.speaker === 'doctor' && username">
          {{ usernamePrefix }} {{ username }}:
        </strong>
        <strong v-else>
          {{ stepData.speaker }}
        </strong>
      </div>
      <v-col class="col-12 px-0 py-2 py-sm-8 my-transparent-dark rounded-xl">
        <div class="px-8 white--text">
          {{ insertUsername(stepData.text) }}
        </div>
      </v-col>
      <v-col class="col-12 pa-0 mt-1 mt-sm-3 d-flex justify-center">
        <v-btn
          dark
          large
          text
          class="rounded-lg back-btn px-0"
          aria-label="back"
          data-cy-back-dialog-btn
          @click="backHelper(stepData.backBtn)"
        >
          <v-icon class="pb-1 mr-1 white--text icon">
            mdi-undo
          </v-icon>
          <span class="back-text text-uppercase">{{ interfaceTexsts.back }}</span>
        </v-btn>
        <v-btn
          v-if="stepData.button"
          :id="stepData.nextBtnId+'-'+language"
          light
          large
          class="analytics-event rounded-lg ml-6 pointer-events-for-analytics"
          aria-label="next"
          data-cy-next-dialog-btn
          @click="nextStep()"
        >
          {{ stepData.button }}
          <v-icon class="ml-1 icon">
            mdi-arrow-right-bold
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import mixins from '@/mixins.js'

export default {
  mixins: [mixins],
  props: {
    showVideo: Boolean,
    stepData: {
      type: Object,
      default: () => {}
    },
    stepsCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState([
      'usernamePrefix'
    ]),
    ...mapGetters([
      'language',
      'screenTalk',
      'interfaceTexsts',
      'username',
      'completedAllMeasurements',
    ]),
  },
  watch: {
    screenTalk (value) {
      if (value) {
        const tm = setTimeout(() => {
          this.$refs.talk.focus()
          clearTimeout(tm)
        }, 500)
      }
    },
    stepsCount () {
      if(!this.showVideo) {
        const tm = setTimeout(() => {
          this.$refs.talk.focus()
          clearTimeout(tm)
        }, 500)
      }
    },
    showVideo (val) {
      if (!val) {
        const tm = setTimeout(() => {
          this.$refs.talk.focus()
          clearTimeout(tm)
        }, 500)
      }
    },
  },
  methods: {
    insertUsername (txt) {
      if (this.username) {
        return txt.replaceAll('__username__', this.username)
      }
      return txt
    },
    nextStep () {
      this.$emit('nextStep')
    },
    backHelper (backBtn) {
      this.$emit('backHelper', backBtn)
    }
  }
}
</script>

<style lang="scss" scoped>

.talk-container {
  outline: none;
}

</style>
