<template>
  <div class="mt-3 text-center">
    <v-btn
      large
      :block="isMobile"
      class="rounded-lg"
      aria-label="next step"
      @click="nextStep"
    >
      <v-icon>
        mdi-check
      </v-icon>
      <span>{{ nextText }}</span>
    </v-btn>
    <v-btn
      large
      text
      :block="isMobile"
      class="rounded-lg"
      aria-label="skip tutorial"
      @click="close"
    >
      <span>{{ skipText }}</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    isMobile: {
      type: Boolean, default: false
    },
    nextText: {
      type: String, default: 'Next tip'
    },
    skipText: {
      type: String, default: 'Skip tutorial'
    }
  },
  methods: {
    nextStep () {
      this.$emit('next-step')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>