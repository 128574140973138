<!-- Normal overlay for first welcome sreen -->

<template>
  <v-dialog
    id="screen-enter"
    v-model="screenWelcome"
    persistent
    fullscreen
    transition="fade-transition"
    class="py-5"
  >
    <v-card
      :style="bgStyles"
      class="d-sm-flex justify-center align-center"
      color="transparent"
    >
      <v-container
        style="max-width: 650px;"
        :class="[preLoaded && isMobile ? 'pt-13' : '']"
      >
        <ProgressLoader transition="fade-transition" />
        <div
          v-if="welcomeStep === 1"
          class="text-center justify-center full-height"
        >
          <h1
            data-cy-app-title
            class="mb-10 mb-lg-3 mb-xl-10 mt-5 white--text mx-auto"
            :class="[isMobile ? 'display-1' : 'display-2' ]"
            style="max-width:500px;"
          >
            Welcome to the MESI Virtual Clinic!
          </h1>
          <div class="white--text mb-10 mb-lg-3 mb-xl-10">
            Our state-of-the-art clinic is equipped with the MESI mTABLET system. This unique healthcare solution takes patient diagnostics to a whole new level.
          </div>
          <v-row
            v-for="(lang, indx) in languages"
            :id="'language-'+lang.nameShort"
            :key="indx"
            class="pl-2 pr-8 py-1 my-0 mx-8 mx-md-16 options-btn text-left rounded-lg"
            :class="{ optionActive: isActive(indx) }"
            :aria-label="'language-'+lang.nameShort"
            :data-cy="'lang-'+lang.nameShort"
            @click="chooseLanguage(lang.nameShort)"
            @mouseover="mouseOver(indx)"
          >
            <v-icon class="white--text icon">
              mdi-chevron-right
            </v-icon>
            <v-col
              class="col-10 col-sm-11 px-0 white--text my-auto"
            >
              {{ lang.name }}
            </v-col>
          </v-row>
        </div>
        <div
          v-else-if="welcomeStep === 2 && interfaceTexsts.dialogWelcome"
          class="d-flex flex-column align-center justify-center text-center white--text"
        >
          <h1
            data-cy-app-title
            class="mb-10"
            :class="[isMobile ? 'display-1' : 'display-2' ]"
            style="max-width:500px;"
          >
            {{ interfaceTexsts.dialogWelcome.welcome_second_title }}
          </h1>
          <v-sheet
            ref="welcome"
            :style="btnWrapStyles"
            color="transparent"
            class="mt-12 black--text no-outline"
            style="width: 100%;"
            tabindex="-1"
            @keyup.enter="next = false"
          > 
            <div class="d-sm-flex justify-center">
              <div class="d-md-inline-block mb-5 mb-md-3 mr-sm-2">
                <v-btn
                  :id="'welcome-explore-btn-'+language"
                  :disabled="!preLoaded"
                  dark
                  x-large
                  class="analytics-event text-none mb-3 py-8 text-left pointer-events-for-analytics"
                  aria-label="explore"
                  :block="$vuetify.breakpoint.smAndDown"
                  data-cy-explore-cta
                  @click="explore"
                >
                  <v-icon
                    large
                    class="mr-3"
                  >
                    mdi-call-split
                  </v-icon>
                  <span class="white--text text-uppercase">{{ interfaceTexsts.dialogWelcome.explore_mode }}</span>
                </v-btn>
                <v-sheet
                  color="transparent"
                  class="caption white--text"
                >
                  {{ interfaceTexsts.dialogWelcome.explore_mode_brackets }}
                </v-sheet>
              </div>
              <div class="d-md-inline-block mb-5 mb-md-3 ml-sm-2">
                <v-btn
                  :id="'welcome-story-btn-'+language"
                  :disabled="!preLoaded"
                  light
                  x-large
                  class="analytics-event text-none mb-3 py-8 text-left pointer-events-for-analytics"
                  aria-label="start story"
                  :block="$vuetify.breakpoint.smAndDown"
                  data-cy-story-cta
                  @click="welcomeStep = 3"
                >
                  <v-icon
                    large
                    class="mr-3"
                  >
                    mdi-frequently-asked-questions
                  </v-icon>
                  <span class="black--text text-uppercase">{{ interfaceTexsts.dialogWelcome.story_mode }}</span>
                </v-btn>
                <v-sheet
                  color="transparent"
                  class="caption white--text"
                >
                  {{ interfaceTexsts.dialogWelcome.story_mode_brackets }}
                </v-sheet>
              </div>
            </div>
          </v-sheet>
        </div>
        <div
          v-else-if="welcomeStep === 3 && interfaceTexsts.dialogWelcome"
          ref="welcomeTwo"
          class="text-center justify-center full-height no-outline"
          tabindex="-1"
          @keyup.enter="startFlow"
        >
          <div
            class="bigger-text mb-10 white--text"
            daya-cy-story-start-txt
          >
            {{ interfaceTexsts.dialogWelcome.story_intro_name }}
          </div>
          <div>
            <div class="white--text d-block d-md-inline-block font-weight-bold mb-3 mr-3 text-uppercase">
              {{ interfaceTexsts.dialogWelcome.story_enter_name }}:
            </div>
            <v-text-field 
              ref="doctorName"
              required
              :value="username"
              dark
              outlined
              :prefix="usernamePrefix"
              class="d-inline-block"
              color="white white--text"
              background-color="rgba(0, 0, 0, 0.51)"
              data-cy-name-input
              @input="updateDoctorsName"
            />
          </div>
          <v-btn
            :id="'enter-name-btn-'+language"
            :disabled="usernameEmpty"
            :style="ctaStyles"
            x-large
            :block="$vuetify.breakpoint.smAndDown"
            data-cy-story-cta-2
            aria-label="meet patient"
            class="analytics-event pointer-events-for-analytics"
            @click="startFlow"
          >
            <span class="black--text">{{ interfaceTexsts.dialogWelcome.story_name_button }}</span>
          </v-btn>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ProgressLoader from '@/components/ProgressLoader'

export default {
  components: {
    ProgressLoader,
  },
  data () {
    return {
      welcomeStep: 1,
      activeOption: 0,
      bgStyles: {
        backgroundImage: `url(${require('@/assets/images/start-bg.jpg')})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      },
      languages: [
        {
          name: 'English',
          nameShort: 'EN'
        },
        {
          name: 'Français',
          nameShort: 'FR'
        },
        {
          name: 'Deutsch',
          nameShort: 'DE'
        },
        {
          name: 'Italiano',
          nameShort: 'IT'
        },
        {
          name: 'Slovenščina',
          nameShort: 'SI'
        },
        {
          name: 'Español',
          nameShort: 'ES'
        }
      ]
    }
  },
  computed: {
     ...mapState([
      'usernamePrefix'
    ]),
    ...mapGetters([
      'language',
      'preLoaded',
      'screenWelcome',
      'interfaceTexsts',
      'username'
    ]),
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    btnWrapStyles () {
      return {
        opacity: this.preLoaded ? 1 : 0,
        transition: 'opacity 0.5s'
      }
    },
    usernameEmpty () {
      return this.username === '' || !this.username
    },
    ctaStyles () {
      return {
        opacity: this.usernameEmpty ? 0 : 1,
        transition: 'opacity 0.5s'
      }
    }
  },
  methods: {
    chooseLanguage (lang) {
      this.welcomeStep = 2
      this.$store.dispatch('LOAD_LANGUAGE', lang)
      if (this.saEvent) {
        this.saEvent('language-select', { language_select: lang })
      }
    },
    updateDoctorsName (newVal) {
      this.$store.commit('UPDATE_USER_NAME', newVal)
    },
    explore () {
      this.$store.commit('EXPLORE')
      this.$store.commit('SHOW_TUTORIAL')
      const tm = setTimeout(() => {
        this.$store.commit('STORY_MODE', false)
        this.$store.commit('PREVIOUS_STEP', 'Welcome')
        if (this.saEvent) {
          this.saEvent('button-click', { button_id: 'explore' })
        }
        clearTimeout(tm)
      }, 1000)
    },
    startFlow () {
      this.$store.commit('DIALOG_WELCOME', false)
      const tm = setTimeout(() => { //has to be delayed, because it changes back too fast and it is visible when changing overlays
        this.welcomeStep = 1
        clearTimeout(tm)
      }, 500)
      this.$store.commit('ACTIVE_DIAGNOSE', 0)
      this.$store.commit('DIALOG_TALK', true)
      if (this.saEvent) {
        this.saEvent('button-click', { button_id: 'story' })
      }
    },
    mouseOver (indx) {
      this.activeOption = indx
    },
    isActive (indx) {
      return this.activeOption === indx
    }
  }
}
</script>

<style lang="scss" scoped>
.full-height {
  height: 100%;
}

.bigger-text {
  font-size: 1.6rem;
}

.list-enter,
.list-leave-to {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}
</style>
