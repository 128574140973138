export default {
  links: [
    [ // panorama 1
      { // left with text
        id: 'left-story',
        positionVector: [1900, -700, -600],
        onClickVector: [2500, -200, -1500],
        panoToIndex: 1,
        type: 'leftSoftStory',
        size: 300
      },
      { // middle with text
        id: 'middle-story',
        positionVector: [1900, -500, 0],
        onClickVector: [3500, -300, -200],
        panoToIndex: 3,
        type: 'forwardStory',
        size: 300
      },
      { // right with text
        id: 'right-story',
        positionVector: [2000, -1000, 800],
        onClickVector: [2000, -200, 1000],
        panoToIndex: 2,
        type: 'rightSharpStory',
        size: 300
      },
      { // left
        id: 'left',
        positionVector: [1900, -700, -600],
        onClickVector: [2500, -200, -1500],
        panoToIndex: 1,
        type: 'leftSoft',
        size: 300
      },
      { // middle
        id: 'middle',
        positionVector: [1900, -500, 0],
        onClickVector: [3500, -300, -200],
        panoToIndex: 3,
        type: 'forward',
        size: 300
      },
      { // right
        id: 'right',
        positionVector: [2000, -1000, 800],
        onClickVector: [2000, -200, 1000],
        panoToIndex: 2,
        type: 'rightSharp',
        size: 300
      }
    ],
    
    [ // panorama 2
      { // left with text
        id: 'left-story',
        positionVector: [800, -800, 1100],
        onClickVector: [2000, -100, 1200],
        panoToIndex: 3,
        type: 'leftSoftStory',
        size: 300
      },
      { // middle with text
        id: 'middle-story',
        positionVector: [-500, -750, 1600],
        onClickVector: [-600, -100, 3000],
        panoToIndex: 2,
        type: 'forwardStory',
        size: 300
      },
      { // right with text
        id: 'right-story',
        positionVector: [-900, -750, 1000],
        onClickVector: [-2300, -100, 1200],
        panoToIndex: 0,
        type: 'rightSoftStory',
        size: 300
      },
      { // left
        id: 'left',
        positionVector: [800, -800, 1100],
        onClickVector: [2000, -100, 1200],
        panoToIndex: 3,
        type: 'leftSoft',
        size: 300
      },
      { // middle
        id: 'middle',
        positionVector: [-600, -750, 1600],
        onClickVector: [-600, -100, 3000],
        panoToIndex: 2,
        type: 'forward',
        size: 300
      },
      { // right
        id: 'right',
        positionVector: [-1200, -750, 1000],
        onClickVector: [-2300, -100, 1200],
        panoToIndex: 0,
        type: 'rightSoft',
        size: 300
      }
    ],

    [ // panorama 3
      { // left with text
        id: 'left-story',
        positionVector: [600, -650, -1800],
        onClickVector: [500, -100, -1800],
        panoToIndex: 1,
        type: 'leftSharpStory',
        size: 300
      },
      { // middle with text
        id: 'middle-story',
        positionVector: [600, -650, -1800],
        onClickVector: [500, -100, -1800],
        panoToIndex: 1,
        type: 'forwardStory',
        size: 300
      },
      { // right with text
        id: 'right-story',
        positionVector: [1700, -680, -1500],
        onClickVector: [1800, -200, -1800],
        panoToIndex: 3,
        type: 'rightSharpStory',
        size: 300
      },
      { // left
        id: 'left',
        positionVector: [-700, -800, -1500],
        onClickVector: [-2000, -100, -1200],
        panoToIndex: 0,
        type: 'leftSharp',
        size: 300
      },
      { // middle
        id: 'middle',
        positionVector: [600, -650, -1800],
        onClickVector: [500, -100, -1800],
        panoToIndex: 1,
        type: 'forward',
        size: 300
      },
      { // right
        id: 'right',
        positionVector: [1700, -680, -1500],
        onClickVector: [1800, -200, -1800],
        panoToIndex: 3,
        type: 'rightSharp',
        size: 300
      }
    ],

    [ // panorama 4
      { // left with text
        id: 'left-story',
        positionVector: [-2100, -900, 600],
        onClickVector: [-2500, -100, 800],
        panoToIndex: 2,
        type: 'leftSoftStory',
        size: 300
      },
      { // middle with text
        id: 'middle-story',
        positionVector: [-2100, -600, 0],
        onClickVector: [-3000, -100, 100],
        panoToIndex: 0,
        type: 'forwardStory',
        size: 300
      },
      { // right with text
        id: 'right-story',
        positionVector: [-1400, -850, -1000],
        onClickVector: [-2200, -100, -2500],
        panoToIndex: 1,
        type: 'rightSharpStory',
        size: 300
      },
      { // left
        id: 'left',
        positionVector: [-2100, -900, 600],
        onClickVector: [-2500, -100, 800],
        panoToIndex: 2,
        type: 'leftSoft',
        size: 300
      },
      { // middle
        id: 'middle',
        positionVector: [-2100, -600, 0],
        onClickVector: [-3000, -100, 100],
        panoToIndex: 0,
        type: 'forward',
        size: 300
      },
      { // right
        id: 'right',
        positionVector: [-1400, -850, -1000],
        onClickVector: [-2200, -100, -2500],
        panoToIndex: 1,
        type: 'rightSharp',
        size: 300
      }
    ],
  ]
}