import panoramaLinks from '@/content/panoramaLinks.js'

import optionsMenu from '@/content/optionsMenu.json'
import diagnoseSteps from '@/content/diagnoseSteps.json'
import panoramaInfospots from '@/content/panoramaInfospots.js'
import interfaceTexsts from '@/content/interfaceTexsts.json'

import optionsMenuSI from '@/content/si/optionsMenu.json'
import diagnoseStepsSI from '@/content/si/diagnoseSteps.json'
import panoramaInfospotsSI from '@/content/si/panoramaInfospots.js'
import interfaceTexstsSI from '@/content/si/interfaceTexsts.json'

import optionsMenuDE from '@/content/de/optionsMenu.json'
import diagnoseStepsDE from '@/content/de/diagnoseSteps.json'
import panoramaInfospotsDE from '@/content/de/panoramaInfospots.js'
import interfaceTexstsDE from '@/content/de/interfaceTexsts.json'

import optionsMenuFR from '@/content/fr/optionsMenu.json'
import diagnoseStepsFR from '@/content/fr/diagnoseSteps.json'
import panoramaInfospotsFR from '@/content/fr/panoramaInfospots.js'
import interfaceTexstsFR from '@/content/fr/interfaceTexsts.json'

import optionsMenuES from '@/content/es/optionsMenu.json'
import diagnoseStepsES from '@/content/es/diagnoseSteps.json'
import panoramaInfospotsES from '@/content/es/panoramaInfospots.js'
import interfaceTexstsES from '@/content/es/interfaceTexsts.json'

import optionsMenuIT from '@/content/it/optionsMenu.json'
import diagnoseStepsIT from '@/content/it/diagnoseSteps.json'
import panoramaInfospotsIT from '@/content/it/panoramaInfospots.js'
import interfaceTexstsIT from '@/content/it/interfaceTexsts.json'


const AUTO_OPEN_DIAGNOSE = context => {
  const tm = setTimeout(() => {
    context.commit('DIALOG_TALK', true)
    clearTimeout(tm)
  }, 1000)
}

const BACK_TO_DIALOG_TALK = context => {
  context.commit('DIALOG_TALK', true)
  context.commit('BACK_TO_DIALOG_TALK', true)
  const tm = setTimeout(() => {
    context.commit('BACK_TO_DIALOG_TALK', false)
    clearTimeout(tm)
  }, 1000)
}

const ENDGAME_DIALOG = ({ commit, state }) => {
  commit('ACTIVE_DIAGNOSE', state.diagnoseSteps.length-1)
  commit('CHECK_ALL_DIAGNOSES')
}

const START_OVER = ({ commit, state }) => {
  switch (state.language) {
    case 'SI':
      commit('DEAD_END_POPUP', interfaceTexstsSI.resetPopup)
      break;
    case 'DE':
      commit('DEAD_END_POPUP', interfaceTexstsDE.resetPopup)
      break;
    case 'FR':
      commit('DEAD_END_POPUP', interfaceTexstsFR.resetPopup)
      break;
    case 'ES':
      commit('DEAD_END_POPUP', interfaceTexstsES.resetPopup)
      break;
    case 'IT':
      commit('DEAD_END_POPUP', interfaceTexstsIT.resetPopup)
      break;
  
    default:
      commit('DEAD_END_POPUP', interfaceTexsts.resetPopup)
      break;
  }
}

const LOAD_LANGUAGE = ({ commit, state }, payload) => {
  commit('CHANGE_LANGUAGE', payload)
  switch (payload) {
    case 'SI':
      commit('SET_INTERFACE_TEXTS', interfaceTexstsSI)
      commit('SET_OPTIONS_STEPS', optionsMenuSI)
      commit('SET_DIAGNOSE_STEPS', diagnoseStepsSI)
      commit('SET_PANORAMA_INFOSPOTS', panoramaInfospotsSI.infospots)
      commit('SET_PANORAMA_STORY_ARROWS', panoramaInfospotsSI.storyArrows)
      break;
    case 'DE':
      commit('SET_INTERFACE_TEXTS', interfaceTexstsDE)
      commit('SET_OPTIONS_STEPS', optionsMenuDE)
      commit('SET_DIAGNOSE_STEPS', diagnoseStepsDE)
      commit('SET_PANORAMA_INFOSPOTS', panoramaInfospotsDE.infospots)
      commit('SET_PANORAMA_STORY_ARROWS', panoramaInfospotsDE.storyArrows)
      break;
    case 'FR':
      commit('SET_INTERFACE_TEXTS', interfaceTexstsFR)
      commit('SET_OPTIONS_STEPS', optionsMenuFR)
      commit('SET_DIAGNOSE_STEPS', diagnoseStepsFR)
      commit('SET_PANORAMA_INFOSPOTS', panoramaInfospotsFR.infospots)
      commit('SET_PANORAMA_STORY_ARROWS', panoramaInfospotsFR.storyArrows)
      break;
    case 'ES':
      commit('SET_INTERFACE_TEXTS', interfaceTexstsES)
      commit('SET_OPTIONS_STEPS', optionsMenuES)
      commit('SET_DIAGNOSE_STEPS', diagnoseStepsES)
      commit('SET_PANORAMA_INFOSPOTS', panoramaInfospotsES.infospots)
      commit('SET_PANORAMA_STORY_ARROWS', panoramaInfospotsES.storyArrows)
      break;
    case 'IT':
      commit('SET_INTERFACE_TEXTS', interfaceTexstsIT)
      commit('SET_OPTIONS_STEPS', optionsMenuIT)
      commit('SET_DIAGNOSE_STEPS', diagnoseStepsIT)
      commit('SET_PANORAMA_INFOSPOTS', panoramaInfospotsIT.infospots)
      commit('SET_PANORAMA_STORY_ARROWS', panoramaInfospotsIT.storyArrows)
      break;
  
    default:
      commit('SET_INTERFACE_TEXTS', interfaceTexsts)
      commit('SET_OPTIONS_STEPS', optionsMenu)
      commit('SET_DIAGNOSE_STEPS', diagnoseSteps)
      commit('SET_PANORAMA_INFOSPOTS', panoramaInfospots.infospots)
      commit('SET_PANORAMA_STORY_ARROWS', panoramaInfospots.storyArrows)
      break;
  }
  commit('SET_PANORAMA_LINKS', panoramaLinks.links)

  if (state.activeDiagnose === state.diagnoseSteps.length-1) {
    commit('CHECK_ALL_DIAGNOSES')
  }
}

export default {
  AUTO_OPEN_DIAGNOSE,
  BACK_TO_DIALOG_TALK,
  ENDGAME_DIALOG,
  START_OVER,
  LOAD_LANGUAGE
}
