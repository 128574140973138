<template>
  <v-dialog
    :value="showTutorialDialog"
    persistent
    fullscreen
    hide-overlay
    transition="fade-transition"
    open-delay="0.3s"
  >
    <v-card 
      class="my-transparent"
    >
      <v-container full-height>
        <v-row class="d-flex justify-center mt-10">
          <v-col 
            cols="12" 
            md="6"
          >
            <v-card-text 
              class="step step-1-wrap pa-5 pb-6 white--text rounded-xl"
              :class="stepClass(0)"
            >
              <h2 class="text-h5 mb-3">
                {{ interfaceTexsts.tutorial.first_step.title }}
              </h2>

              <p v-html="interfaceTexsts.tutorial.first_step.html_text" />

              <div class="text-left">
                <MouseTutorialImage
                  class="mouse-tutorial-img"
                  aria-label="move in images with mouse"
                />
              </div>
            </v-card-text>
            <TutorialCTAs
              :next-text="interfaceTexsts.tutorial.first_step.next_text"
              :skip-text="interfaceTexsts.tutorial.first_step.skip_text"
              :class="stepClass(0)"
              @next-step="nextStep" 
              @close="close" 
            />
            <div 
              class="step step-2 map-settings-wrap"
              :class="stepClass(1)"
            >
              <div class="inner pa-5 rounded-xl">
                <div class="text-right">
                  <v-icon color="white">
                    {{ isMobile ? 'mdi-arrow-up' : 'mdi-arrow-right' }}
                  </v-icon>
                </div>
                <div class="my-3">
                  {{ interfaceTexsts.tutorial.second_step.title }}
                  <ul
                    class="my-2"
                    v-html="interfaceTexsts.tutorial.second_step.html_text"
                  />
                  <div :class="{'d-none': isMobile}">
                    {{ interfaceTexsts.tutorial.second_step.tip }}
                  </div>
                </div>
              </div>
              <TutorialCTAs 
                :next-text="interfaceTexsts.tutorial.second_step.next_text"
                :skip-text="interfaceTexsts.tutorial.second_step.skip_text"
                @next-step="nextStep" 
                @close="close" 
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapGetters } from 'vuex'
import MouseTutorialImage from '@/assets/icons/mouse-press-rotate.svg'
import TutorialCTAs from '@/components/TutorialCTAs'

export default {
  name: 'Tutorial',
  components: {
    MouseTutorialImage, TutorialCTAs
  },
  data() {
    return {
      showTutorialDialog: true,
      showTutorialTimed: true,
      currentStep: 0,
      steps: [
        {
          active: true
        },
        {
          active: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'interfaceTexsts'
    ]),
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    stepClass (indx = 0) {
      return { 'd-none': indx !== this.currentStep }
    },
    close () {
      this.showTutorialDialog = false
      this.$store.commit('SHOW_RIGHT_MENU', true)
      this.$store.commit('SHOW_LEFT_MENU', true)
    },
    nextStep () {
      const allStepsLength = this.steps.length
      const newStepIndx = (this.currentStep + 1) % (this.steps.length)

      if(newStepIndx === 0) {
        this.close()
      } else {
        this.currentStep = newStepIndx
      }
      
      // set all to false except the new index to true
      this.steps.map( (obj, index) => {
        //console.log(index, obj.active, this.currentStep)
        if (index === this.newStepIndx) {
          obj.active = true
        } else {
          obj.active = false
        }
        return obj
      })
    }
  }
}

</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.skip-tutorial-btn {
  position: fixed;
  bottom: 30px;
  left: 10px;
  right: 10px;
}

.step-1-wrap {
  background-color: rgba(0, 0, 0, 0.51);
}

.mouse-tutorial-img {
  position: relative;
  width: 100px;
  height: 100px;
  
  .anim {
    @include animate_opacity(3s, 0.2);
  }

  .anim-end {
    &.ar-right {
      animation: move_r 2s infinite;

      @keyframes move_r {
        0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(30px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }
  }
}

.map-settings-wrap {
  max-width: 450px;
  position: fixed;
  top: 60px;
  right: 10px;
  color: white;

  .inner {
    padding: 15px;
    padding-top: 10px;
    background-color: rgba(0, 0, 0, 0.51);
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    top: 20px;
    right: 200px;
  }
}
</style>
