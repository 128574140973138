<template>
  <v-dialog
    :value="videoPopup"
    persistent
    fullscreen
    hide-overlay
    :retain-focus="false"
    transition="fade-transition"
    open-delay="0.3s"
    class="py-5 screen my-dialog"
  > 
    <v-card class="my-transparent">
      <v-container
        fluid
        class="pa-0"
      >
        <v-row
          ref="videoPopup"
          class="full-height text-center no-outline align-content-center ma-0"
          tabindex="-1"
          @keyup.delete="close"
          @keyup.esc="close"
        >
          <v-col
            class="fill-height d-flex pa-0"
            style="background: rgba(255,255,255,0.8);"
          >
            <v-responsive
              class="video-container my-0"
              :aspect-ratio="16/9"
            >
              <iframe
                v-if="videoPopup"
                :src="videoSrc"
                frameborder="0"
                allowfullscreen
                allow="autoplay; fullscreen; picture-in-picture"
              />
            </v-responsive>
          </v-col>
          <VideoDoneCTA
            @storyVideoClose="storyVideoClose"
            @storyVideoCloseBack="storyVideoCloseBack"
          />
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import VideoDoneCTA from '@/components/VideoDoneCTA'
import { mapGetters } from 'vuex'

export default {
  components: {
    VideoDoneCTA
  },
  data () {
    return {
      musicWasTurnedOff: false
    }
  },
  computed: {
    ...mapGetters([
      'videoPopup',
      'storyMode',
      'videoSrc',
      'activePanorama',
      'music'
    ])
  },
  watch: {
    videoPopup (value) {
      if (value) {
        if (this.music) {
          this.$store.commit('TOGGLE_MUSIC')
          this.musicWasTurnedOff = true
        }
      } else {
        if (this.musicWasTurnedOff) {
          this.$store.commit('TOGGLE_MUSIC')
          this.musicWasTurnedOff = false
        }
      }
    }
  },
  methods: {
    storyVideoClose () {
      this.$emit('nextStep')
      const tm = setTimeout(() => {
        this.$emit('closeVideo')
        clearTimeout(tm)
      }, 500)
    },
    storyVideoCloseBack () {
      this.$emit('setBackToTablet')
      this.$emit('closeVideo')
    }
  }
}
</script>

<style lang="scss" scoped>

.full-height {
  height: 100vh;
}

.my-transparent {
  backdrop-filter: blur(4px);
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

</style>
