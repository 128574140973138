export default {
  storyArrows: {
    forward: require('@/assets/icons/arrows/VC_arrow_FORWARD.png'),
    leftSoft: require('@/assets/icons/arrows/VC_arrow_LEFT-soft.png'),
    leftSharp: require('@/assets/icons/arrows/VC_arrow_LEFT-sharp.png'),
    rightSoft: require('@/assets/icons/arrows/VC_arrow_RIGHT-soft.png'),
    rightSharp: require('@/assets/icons/arrows/VC_arrow_RIGHT-sharp.png'),

    forwardStory: require('@/assets/icons/arrows/VC_arrow-text_FORWARD_ITA.png'),
    leftSoftStory: require('@/assets/icons/arrows/VC_arrow-text_LEFT-soft_ITA.png'),
    leftSharpStory: require('@/assets/icons/arrows/VC_arrow-text_LEFT-sharp_ITA.png'),
    rightSoftStory: require('@/assets/icons/arrows/VC_arrow-text_RIGHT-soft_ITA.png'),
    rightSharpStory: require('@/assets/icons/arrows/VC_arrow-text_RIGHT-sharp_ITA.png')
  },
  infospots: [
    [ // panorama 1
      { // Music on
        id: 'music-on',
        positionVector: [1000, -900, 2900],
        contentType: 'music',
        type: 'sound',
        size: 150
      },
      { // Music off
        id: 'music-off',
        positionVector: [1000, -900, 2900],
        contentType: 'music',
        type: 'soundOff',
        size: 150
      },
      { // Testimonials
        id: 'Testimonials',
        positionVector: [1850, 850, 2900],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Scopri cosa dicono di noi i nostri clienti.',
          text: `Questo aprirà una nuova scheda del browser.<br>Vuoi continuare?`,
          url: 'https://www.mesimedical.com/it/tag/terza-opinione/',
          buttonText: 'SÌ',
          buttonAction: 'openLink',
          btnId: 'testimonials'
        },
        size: 300
      }
    ],
    
    [ // panorama 2
      { // BP
        id: 'BP',
        positionVector: [1400, -200, -3300],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612527906?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 350
      },
      { // SPO
        id: 'SPO',
        positionVector: [-2200, -1200, -450],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612528569?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 250
      },
      { // BP explore
        id: 'BP-explore',
        positionVector: [1400, -200, -3300],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/619710482?autoplay=1&title=0&byline=0&portrait=0',
        size: 350
      },
      { // SPO explore
        id: 'SPO-explore',
        positionVector: [-2200, -1200, -450],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/619712176?autoplay=1&title=0&byline=0&portrait=0',
        size: 250
      },
      { // Healthyarteries Poster
        id: 'Healthyarteries',
        positionVector: [-2200, 600, -600],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Ottieni maggiori informazioni sulla campagna di sensibilizzazione Arterie Sane.',
          text: `Questo aprirà una nuova scheda del browser.<br>Vuoi continuare?`,
          url: 'https://healthyarteries.org/',
          buttonText: 'SÌ',
          buttonAction: 'openLink',
          btnId: 'healthyarteries'
        },
        size: 200
      },
      { // Books BP
        id: 'BP-books',
        positionVector: [900, -1800, -3300],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Accedi agli articoli pertinenti alla pressione sanguigna (BP).',
          text: `Questo aprirà una nuova scheda del browser.<br>Vuoi continuare?`,
          url: 'https://www.mesimedical.com/it/pressione-del-sangue-e-altre-misurazioni-in-ununica-soluzione/',
          buttonText: 'SÌ',
          buttonAction: 'openLink',
          btnId: 'BP-book'
        },
        size: 350
      },
      { // Books SPO2
        id: 'SPO-books',
        positionVector: [1600, -2700, -3300],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Accedi agli articoli pertinenti alla pulsossimetria<span class="keep-word-break mx-1">(SpO<sub>2</sub>).</span>',
          text: `Questo aprirà una nuova scheda del browser.<br>Vuoi continuare?`,
          url: 'https://www.mesimedical.com/it/saturazione-dellossigeno-nel-sangue-a-portata-di-mano/',
          buttonText: 'SÌ',
          buttonAction: 'openLink',
          btnId: 'SPO-book'
        },
        size: 350
      },
      { // Books 6MWT
        id: '6MWT-books',
        positionVector: [650, -3600, -3300],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Accedi agli articoli pertinenti al test del cammino in 6 minuti (6MWT).',
          text: `Questo aprirà una nuova scheda del browser.<br>Vuoi continuare?`,
          url: 'https://www.mesimedical.com/it/tag/6mwt/',
          buttonText: 'SÌ',
          buttonAction: 'openLink',
          btnId: '6MWT-book'
        },
        size: 350
      }
    ],

    [ // panorama 3
      { // mRecords explore
        id: 'm-records',
        positionVector: [5000, -810, -1664],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/528385791?autoplay=1&title=0&byline=0&portrait=0',
        size: 350
      },
      { // Table: Catalogue
        id: 'Catalogue',
        positionVector: [1800, -1500, -1800],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Tieni tutte le informazioni su MESI mTABLET nello stesso posto.',
          text: `Questo scaricherà un documento PDF.<br>Vuoi continuare?`,
          url: 'https://f.hubspotusercontent40.net/hubfs/5157245/MESI%20PDF%20Materials/MESI%20IT%20PDF%20materials/MESI_mTABLET_catalogue_IT.pdf',
          buttonText: 'SÌ',
          buttonAction: 'openLink',
          btnId: 'Catalouge-book'
        },
        size: 200
      },
      { // Books CVD
        id: 'CVD-books',
        positionVector: [-1000, 300, -600],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Accedi agli articoli pertinenti alla malattia cardiovascolare (CVD).',
          text: `Questo aprirà una nuova scheda del browser.<br>Vuoi continuare?`,
          url: 'https://www.mesimedical.com/it/tag/malattia-cardiovascolare-cvd/',
          buttonText: 'SÌ',
          buttonAction: 'openLink',
          btnId: 'CVD-book'
        },
        size: 100
      },
      { // Books PAD
        id: 'PAD-books',
        positionVector: [-1000, 0, -150],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Accedi agli articoli pertinenti alla Arteriopatia periferica (PAD).',
          text: `Questo aprirà una nuova scheda del browser.<br>Vuoi continuare?`,
          url: 'https://www.mesimedical.com/it/tag/arteriopatia-periferica-pad/',
          buttonText: 'SÌ',
          buttonAction: 'openLink',
          btnId: 'PAD-book'
        },
        size: 100
      },
      { // Books CAD
        id: 'CAD-books',
        positionVector: [-1000, -300, -580],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Accedi agli articoli pertinenti alla malattia coronarica (CAD).',
          text: `Questo aprirà una nuova scheda del browser.<br>Vuoi continuare?`,
          url: 'https://www.mesimedical.com/it/tag/malattia-coronarica-cad/',
          buttonText: 'SÌ',
          buttonAction: 'openLink',
          btnId: 'CAD-book'
        },
        size: 100
      }
    ],

    [ // panorama 4
      { // ABI
        id: 'ABI',
        positionVector: [2500, 570, -800],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612527510?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 250
      },
      { // TBI
        id: 'TBI',
        positionVector: [2500, 530, -220],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612528677?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 250
      },
      { // ECG
        id: 'ECG',
        positionVector: [2500, 530, 750],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612528396?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 250
      },
      { // ABI explore
        id: 'ABI-explore',
        positionVector: [2500, 570, -800],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/619709246?autoplay=1&title=0&byline=0&portrait=0',
        size: 250
      },
      { // TBI explore
        id: 'TBI-explore',
        positionVector: [2500, 530, -220],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/619712606?autoplay=1&title=0&byline=0&portrait=0',
        size: 250
      },
      { // ECG explore
        id: 'ECG-explore',
        positionVector: [2500, 530, 750],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/619710636?autoplay=1&title=0&byline=0&portrait=0',
        size: 250
      },
      { // Trolley
        id: 'Trolley',
        positionVector: [-1360, -70, 2500],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Familiarizza con la conservazione ordinata mobile per i dispositivi wireless.',
          text: `Questo scaricherà un documento PDF.<br>Vuoi continuare?`,
          url: 'https://f.hubspotusercontent40.net/hubfs/5157245/MESI%20PDF%20Materials/MESI%20IT%20PDF%20materials/MESI_mTABLET_Trolley_2p_IT.pdf',
          buttonText: 'SÌ',
          buttonAction: 'openLink',
          btnId: 'trolley'
        },
        size: 250
      },
      { // Books ABI
        id: 'ABI-books',
        positionVector: [2500, -1100, -900],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Accedi agli articoli pertinenti all’indice caviglia-braccio (ABI).',
          text: `Questo aprirà una nuova scheda del browser.<br>Vuoi continuare?`,
          url: 'https://www.mesimedical.com/it/tag/indice-caviglia-braccio-abi/',
          buttonText: 'SÌ',
          buttonAction: 'openLink',
          btnId: 'ABI-book'
        },
        size: 250
      },
      { // Books ECG
        id: 'ECG-books',
        positionVector: [2500, -1700, -630],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Accedi agli articoli pertinenti all’elettrocardiografia (ECG).',
          text: `Questo aprirà una nuova scheda del browser.<br>Vuoi continuare?`,
          url: 'https://www.mesimedical.com/tag/electrocardiogram/',
          buttonText: 'SÌ',
          buttonAction: 'openLink',
          btnId: 'ECG-book'
        },
        size: 250
      },
      { // Books TBI
        id: 'TBI-books',
        positionVector: [2500, -2200, -1100],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Accedi agli articoli pertinenti all’indice alluce-braccio (TBI).',
          text: `Questo aprirà una nuova scheda del browser.<br>Vuoi continuare?`,
          url: 'https://www.mesimedical.com/tag/toe-brachial-index/',
          buttonText: 'SÌ',
          buttonAction: 'openLink',
          btnId: 'TBI-book'
        },
        size: 250
      },
    ],
  ]
}