export default {
  storyArrows: {
    forward: require('@/assets/icons/arrows/VC_arrow_FORWARD.png'),
    leftSoft: require('@/assets/icons/arrows/VC_arrow_LEFT-soft.png'),
    leftSharp: require('@/assets/icons/arrows/VC_arrow_LEFT-sharp.png'),
    rightSoft: require('@/assets/icons/arrows/VC_arrow_RIGHT-soft.png'),
    rightSharp: require('@/assets/icons/arrows/VC_arrow_RIGHT-sharp.png'),

    forwardStory: require('@/assets/icons/arrows/VC_arrow-text_FORWARD_ESP.png'),
    leftSoftStory: require('@/assets/icons/arrows/VC_arrow-text_LEFT-soft_ESP.png'),
    leftSharpStory: require('@/assets/icons/arrows/VC_arrow-text_LEFT-sharp_ESP.png'),
    rightSoftStory: require('@/assets/icons/arrows/VC_arrow-text_RIGHT-soft_ESP.png'),
    rightSharpStory: require('@/assets/icons/arrows/VC_arrow-text_RIGHT-sharp_ESP.png')
  },
  infospots: [
    [ // panorama 1
      { // Music on
        id: 'music-on',
        positionVector: [1000, -900, 2900],
        contentType: 'music',
        type: 'sound',
        size: 150
      },
      { // Music off
        id: 'music-off',
        positionVector: [1000, -900, 2900],
        contentType: 'music',
        type: 'soundOff',
        size: 150
      },
      { // Testimonials
        id: 'Testimonials',
        positionVector: [1850, 850, 2900],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Descubra lo que opinan de nosotros nuestros clientes.',
          text: `Se abrirá una nueva pestaña del navegador.<br>¿Quiere continuar?`,
          url: 'https://www.mesimedical.com/es/tag/tercera-opinion/',
          buttonText: 'SÍ',
          buttonAction: 'openLink',
          btnId: 'testimonials'
        },
        size: 300
      }
    ],
    
    [ // panorama 2
      { // BP
        id: 'BP',
        positionVector: [1400, -200, -3300],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612471238?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 350
      },
      { // SPO
        id: 'SPO',
        positionVector: [-2200, -1200, -450],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612472053?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 250
      },
      { // BP explore
        id: 'BP-explore',
        positionVector: [1400, -200, -3300],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/618862531?autoplay=1&title=0&byline=0&portrait=0',
        size: 350
      },
      { // SPO explore
        id: 'SPO-explore',
        positionVector: [-2200, -1200, -450],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/618872136?autoplay=1&title=0&byline=0&portrait=0',
        size: 250
      },
      { // Healthyarteries Poster
        id: 'Healthyarteries',
        positionVector: [-2200, 600, -600],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Obtenga más información sobre la campaña de concienciación Arterias Sanas.',
          text: `Se abrirá una nueva pestaña del navegador.<br>¿Quiere continuar?`,
          url: 'https://healthyarteries.org/',
          buttonText: 'SÍ',
          buttonAction: 'openLink',
          btnId: 'healthyarteries'
        },
        size: 200
      },
      { // Books BP
        id: 'BP-books',
        positionVector: [900, -1800, -3300],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Acceda a los artículos relevantes sobre Presión arterial (BP).',
          text: `Se abrirá una nueva pestaña del navegador.<br>¿Quiere continuar?`,
          url: 'https://www.mesimedical.com/es/tension-arterial-y-otras-mediciones-en-un-mismo-lugar/',
          buttonText: 'SÍ',
          buttonAction: 'openLink',
          btnId: 'BP-book'
        },
        size: 350
      },
      { // Books SPO2
        id: 'SPO-books',
        positionVector: [1600, -2700, -3300],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Acceda a los artículos relevantes sobre Oximetría de pulso<span class="keep-word-break mx-1">(SpO<sub>2</sub>).</span>',
          text: `Se abrirá una nueva pestaña del navegador.<br>¿Quiere continuar?`,
          url: 'https://www.mesimedical.com/es/la-saturacion-de-oxigeno-en-sangre-en-la-punta-de-sus-dedos/',
          buttonText: 'SÍ',
          buttonAction: 'openLink',
          btnId: 'SPO-book'
        },
        size: 350
      },
      { // Books 6MWT
        id: '6MWT-books',
        positionVector: [650, -3600, -3300],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Acceda a los artículos relevantes sobre Prueba de marcha de 6 minutos (6MWT).',
          text: `Se abrirá una nueva pestaña del navegador.<br>¿Quiere continuar?`,
          url: 'https://www.mesimedical.com/es/tag/6mwt/',
          buttonText: 'SÍ',
          buttonAction: 'openLink',
          btnId: '6MWT-book'
        },
        size: 350
      }
    ],

    [ // panorama 3
      { // mRecords explore
        id: 'm-records',
        positionVector: [5000, -810, -1664],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/528385791?autoplay=1&title=0&byline=0&portrait=0',
        size: 350
      },
      { // Table: Catalogue
        id: 'Catalogue',
        positionVector: [1800, -1500, -1800],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Disponga de toda la información sobre MESI mTABLET en un único lugar.',
          text: `Se descargará un documento PDF.<br>¿Quiere continuar?`,
          url: 'https://f.hubspotusercontent40.net/hubfs/5157245/MESI%20PDF%20Materials/MESI%20ES%20PDF%20materials/MESI_mTABLET_catalogue_ES.pdf',
          buttonText: 'SÍ',
          buttonAction: 'openLink',
          btnId: 'Catalouge-book'
        },
        size: 200
      },
      { // Books CVD
        id: 'CVD-books',
        positionVector: [-1000, 300, -600],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Acceda a los artículos relevantes sobre la Enfermedad cardiovascular (ECV).',
          text: `Se abrirá una nueva pestaña del navegador.<br>¿Quiere continuar?`,
          url: 'https://www.mesimedical.com/es/tag/enfermedad-cardiovascular-ecv/',
          buttonText: 'SÍ',
          buttonAction: 'openLink',
          btnId: 'CVD-book'
        },
        size: 100
      },
      { // Books PAD
        id: 'PAD-books',
        positionVector: [-1000, 0, -150],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Acceda a los artículos relevantes sobre la Enfermedad arterial periférica (EAP).',
          text: `Se abrirá una nueva pestaña del navegador.<br>¿Quiere continuar?`,
          url: 'https://www.mesimedical.com/es/tag/enfermedad-arterial-periferica-eap/',
          buttonText: 'SÍ',
          buttonAction: 'openLink',
          btnId: 'PAD-book'
        },
        size: 100
      },
      { // Books CAD
        id: 'CAD-books',
        positionVector: [-1000, -300, -580],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Acceda a los artículos relevantes sobre la Enfermedad arterial coronaria (EAC).',
          text: `Se abrirá una nueva pestaña del navegador.<br>¿Quiere continuar?`,
          url: 'https://www.mesimedical.com/es/tag/enfermedad-arterial-coronaria-eac/',
          buttonText: 'SÍ',
          buttonAction: 'openLink',
          btnId: 'CAD-book'
        },
        size: 100
      }
    ],

    [ // panorama 4
      { // ABI
        id: 'ABI',
        positionVector: [2500, 570, -800],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612470353?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 250
      },
      { // TBI
        id: 'TBI',
        positionVector: [2500, 530, -220],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612472158?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 250
      },
      { // ECG
        id: 'ECG',
        positionVector: [2500, 530, 750],
        contentType: 'story',
        url: 'https://player.vimeo.com/video/612471476?autoplay=1&title=0&byline=0&portrait=0',
        type: 'click',
        size: 250
      },
      { // ABI explore
        id: 'ABI-explore',
        positionVector: [2500, 570, -800],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/618857100?autoplay=1&title=0&byline=0&portrait=0',
        size: 250
      },
      { // TBI explore
        id: 'TBI-explore',
        positionVector: [2500, 530, -220],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/618873743?autoplay=1&title=0&byline=0&portrait=0',
        size: 250
      },
      { // ECG explore
        id: 'ECG-explore',
        positionVector: [2500, 530, 750],
        contentType: 'video',
        type: 'play',
        url: 'https://player.vimeo.com/video/618867144?autoplay=1&title=0&byline=0&portrait=0',
        size: 250
      },
      { // Trolley
        id: 'Trolley',
        positionVector: [-1360, -70, 2500],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Familiarícese con el almacenamiento móvil para sus dispositivos inalámbricos.',
          text: `Se descargará un documento PDF.<br>¿Quiere continuar?`,
          url: 'https://f.hubspotusercontent40.net/hubfs/5157245/MESI%20PDF%20Materials/MESI%20ES%20PDF%20materials/MESI_mTABLET_Trolley_2p_ES.pdf',
          buttonText: 'SÍ',
          buttonAction: 'openLink',
          btnId: 'trolley'
        },
        size: 250
      },
      { // Books ABI
        id: 'ABI-books',
        positionVector: [2500, -1100, -900],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Acceda a los artículos relevantes sobre Índice tobillo-brazo (ITB).',
          text: `Se abrirá una nueva pestaña del navegador.<br>¿Quiere continuar?`,
          url: 'https://www.mesimedical.com/es/tag/indice-tobillo-brazo-itb/',
          buttonText: 'SÍ',
          buttonAction: 'openLink',
          btnId: 'ABI-book'
        },
        size: 250
      },
      { // Books ECG
        id: 'ECG-books',
        positionVector: [2500, -1700, -630],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Acceda a los artículos relevantes sobre Electrocardiograma (ECG).',
          text: `Se abrirá una nueva pestaña del navegador.<br>¿Quiere continuar?`,
          url: 'https://www.mesimedical.com/tag/electrocardiogram/',
          buttonText: 'SÍ',
          buttonAction: 'openLink',
          btnId: 'ECG-book'
        },
        size: 250
      },
      { // Books TBI
        id: 'TBI-books',
        positionVector: [2500, -2200, -1100],
        contentType: 'link',
        type: 'read',
        popup: {
          title: 'Acceda a los artículos relevantes sobre Índice dedo del pie-brazo (IDB).',
          text: `Se abrirá una nueva pestaña del navegador.<br>¿Quiere continuar?`,
          url: 'https://www.mesimedical.com/es/tag/indice-dedo-del-pie-brazo/',
          buttonText: 'SÍ',
          buttonAction: 'openLink',
          btnId: 'TBI-book'
        },
        size: 250
      },
    ],
  ]
}