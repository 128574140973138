const language = state => state.language
const interfaceTexsts = state => state.interfaceTexsts
const panoramaLinks = state => state.panoramaLinks
const panoramaInfospots = state => state.panoramaInfospots
const panoramaStoryArrows = state => state.panoramaStoryArrows
const rightDrawer = state => state.rightDrawer
const leftDrawer = state => state.leftDrawer
const showTutorial = state => state.showTutorial
const tutorialShownBefore = state => state.tutorialShownBefore
const preLoaded = state => state.preLoaded
const storyMode = state => state.storyMode
const exploreMode = state => state.exploreMode
const levelComplete = state => state.levelComplete
const flowOneComplete = state => state.flowOneComplete
const activePanorama = state => state.activePanorama
const showDialogToStartMeasurement = state => state.showDialogToStartMeasurement
const resetStartOver = state => state.resetStartOver
const openLink = state => state.openLink
const screenWelcome = state => state.screenWelcome
const screenTalk = state => state.screenTalk
const screenOptions = state => state.screenOptions
const deadEndPopup = state => state.deadEndPopup
const deadEndPopupData = state => state.deadEndPopupData
const videoPopup = state => state.videoPopup
const videoSrc = state => state.videoSrc
const formPopup = state => state.formPopup
const formPopupType = state => state.formPopupType
const optionsStep = state => state.optionsStep
const optionsMenu = state => state.optionsMenu
const activeDiagnose = state => state.activeDiagnose
const previousDiagnoses = state => state.previousDiagnoses
const previousOptions = state => state.previousOptions
const previousStep = state => state.previousStep
const talkStepSaved = state => state.talkStepSaved
const talkStepContinue = state => state.talkStepContinue
const backToDialogTalk = state => state.backToDialogTalk
const completedAllMeasurements = state => state.completedAllMeasurements
const diagnose = state => state.diagnose
const diagnoseSteps = state => state.diagnoseSteps
const username = state => state.username

export default {
  language,
  interfaceTexsts,
  panoramaLinks,
  panoramaInfospots,
  panoramaStoryArrows,
  rightDrawer,
  leftDrawer,
  showTutorial,
  tutorialShownBefore,
  preLoaded,
  storyMode,
  exploreMode,
  levelComplete,
  flowOneComplete,
  activePanorama,
  showDialogToStartMeasurement,
  resetStartOver,
  openLink,
  screenWelcome,
  screenTalk,
  screenOptions,
  deadEndPopup,
  deadEndPopupData,
  videoPopup,
  videoSrc,
  formPopup,
  formPopupType,
  optionsStep,
  optionsMenu,
  activeDiagnose,
  previousDiagnoses,
  previousOptions,
  previousStep,
  talkStepSaved,
  talkStepContinue,
  backToDialogTalk,
  completedAllMeasurements,
  diagnose,
  diagnoseSteps,
  username
}
