const audio = {
    state: () => ({ 
      audios: {
        id: 'background-music',
        name: 'background-music',
        file: new Audio(require('@/assets/audio/david-kocmur-for-mesi.mp3')),
        isPlaying: false
      }
     }),
    mutations: {
      TOGGLE_MUSIC (state) {
        if (!state.audios.isPlaying) {
          state.audios.file.play();
          state.audios.file.loop = true
          state.audios.isPlaying = true
        } else {
          state.audios.file.pause();
          state.audios.isPlaying = false
        }
      }
    },
    getters: {
      music (state) {return state.audios.isPlaying}
    }
  }

  export default audio