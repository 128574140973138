<template>
  <v-navigation-drawer
    :is="smallIcons ? 'div' : 'v-navigation-drawer'"
    v-if="interfaceTexsts.menuLeft"
    absolute
    right
    :temporary="false"
    :hide-overlay="true"
    :value="rightDrawer"
    width="auto"
    :class="smallIcons ? 'minimap-wrap text-right d-flex flex-column' : 'px-3 text-center'"
    stateless
    style="z-index: 601;"
  >
    <minimap class="minimap d-none d-md-block" />

    <div class="d-flex d-md-none mb-5">
      <v-btn
        icon
        class="mt-3 ml-auto"
        aria-label="close menu"
        data-cy-close-menu-right
        @click="closeRightDrawer"
      >
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>

    <template
      v-for="btn in minimapButtons"
      class="pa-0"
    >
      <v-divider
        :key="btn.name"
        class="d-block d-md-none my-4"
      />

      <v-tooltip
        :key="btn.text"
        :value="showTooltipsMobile && !smallIcons"
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :id="btn.name+'-mobile-'+language"
            class="analytics-event menu-icon"
            :class="btn.class"
            :small="smallIcons"
            v-bind="attrs"
            :aria-label="btn.text"
            @click="clickHelper(btn.action, btn.url)"
            v-on="on"
          >
            {{ btn.icon }}
          </v-icon>
        </template>
        <span>{{ btn.text }}</span>
      </v-tooltip>

      <v-divider
        :key="btn.icon"
        class="d-block d-md-none my-4"
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import minimap from '@/assets/images/Virtual-Clinic_minimap.svg'
import mixins from '@/mixins.js'

export default {
  name: 'Navbar',
  components: {
    minimap
  },
  mixins: [mixins],
  data(){
    return {
      showTooltipsMobile: false
    }
  },
  computed: {
    ...mapGetters([
      'rightDrawer',
      'interfaceTexsts',
      'music',
      'storyMode',
      'screenWelcome',
      'screenTalk',
      'screenOptions',
      'activeDiagnose',
      'activePanorama',
      'previousStep',
      'previousDiagnoses',
      'language'
    ]),
    smallIcons () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    minimapButtons () {
      return [
        {
          name: 'configurator',
          class: 'configurator d-inline-block d-lg-none options-btn',
          text: this.interfaceTexsts.menuLeft.configurator,
          icon: 'mdi-order-bool-ascending-variant',
          action: 'link',
          url: 'https://configurator.mesimedical.com'
        },
        {
          name: 'presentation',
          class: 'presentation d-inline-block d-lg-none',
          text: this.interfaceTexsts.menuLeft.book_presentation,
          icon: 'mdi-projector-screen-outline',
          action: 'bookPresentation',
          url: false
        },
        {
          name: 'inquiry',
          class: 'inquiry d-inline-block d-lg-none',
          text: this.interfaceTexsts.menuLeft.send_inquiry,
          icon: 'mdi-file-question',
          action: 'inquiry',
          url: false
        },
        {
          name: 'volume',
          class: 'volume',
          text: this.music ? this.interfaceTexsts.minimapButtons.music_stop : this.interfaceTexsts.minimapButtons.music_play,
          icon: this.music ? 'mdi-volume-off' : 'mdi-volume-high',
          action: 'toggleMusic',
          url: false
        },
        {
          name: 'story',
          class: 'story',
          text: this.storyMode ? this.interfaceTexsts.minimapButtons.explore : this.interfaceTexsts.minimapButtons.story,
          icon: this.storyMode ? 'mdi-call-split' : 'mdi-frequently-asked-questions',
          action: 'modeChange',
          url: false
        },
        {
          name: 'reset',
          class: 'reset',
          text: this.interfaceTexsts.minimapButtons.reset,
          icon: 'mdi-restart',
          action: 'reset',
          url: false
        }
      ]
    }
  },
  watch: {
    activePanorama () {
      this.pinVisibility ()
    },
    rightDrawer (val) {
      if (val) {
        setTimeout(() => {
          this.showTooltipsMobile = true
        }, 300);
      } else {
        this.showTooltipsMobile = false
      }
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.pinVisibility ()
    })
  },
  methods: {
    clickHelper(action, url) {
      if(url) {
        window.open(url, '_blank,noopener');
      } else {
        this[action]()
      }
    },
    modeChange () {
      if (this.storyMode) {
        this.switchModeToExplore()
      } else {
        this.switchModeToStory()
      }
    },
    closeRightDrawer () {
      this.$store.commit('SHOW_RIGHT_MENU', false)
    },
    pinVisibility () {
      this.removeAllActiveClasses ()

      let svgPin = document.getElementById('pin-'+this.activePanorama);
      if(svgPin) {
        svgPin.classList.add('active');
      }
    },
    removeAllActiveClasses () {
      const activeElements = this.$el.getElementsByClassName('active')
      if (typeof activeElements !== 'undefined' && activeElements.length > 0) {
        activeElements[0].classList.remove('active');
      }
    },
    switchModeToExplore () {
      this.$store.commit('SAVE_LAST_DIAGNOSE', [this.activeDiagnose, this.activePanorama])
      if (this.screenWelcome) {
        this.$store.commit('PREVIOUS_STEP', 'Welcome')
      } else if (this.screenTalk) {
        this.$store.commit('PREVIOUS_STEP', 'Talk')
      } else if (this.screenOptions) {
        this.$store.commit('PREVIOUS_STEP', 'Options')
      } else {
        this.$store.commit('PREVIOUS_STEP', 'MoveStep')
      }
      this.$store.commit('EXPLORE')
      const tm = setTimeout(() => {
        this.$store.commit('STORY_MODE', false)
        clearTimeout(tm)
      }, 1000)
    },
    switchModeToStory () {
      if (this.previousStep === 'Welcome') {
        this.$store.commit('STORY_MODE', true)
        this.$store.commit('ACTIVE_PANORAMA', 0)
        this.$store.commit('DIALOG_WELCOME', true)
      } else if (this.previousStep === 'Talk') {
        this.$store.commit('STORY_MODE', true)
        this.$store.commit('ACTIVE_DIAGNOSE', this.previousDiagnoses[this.previousDiagnoses.length - 1].diagnose)
        this.$store.commit('ACTIVE_PANORAMA', this.previousDiagnoses[this.previousDiagnoses.length - 1].panorama)
        this.$store.commit('TALK_STEP_CONTINUE', true)
        const tm = setTimeout(() => {
          this.$store.commit('DIALOG_TALK', true)
          this.$store.commit('DELETE_LAST_DIAGNOSE')
          this.$store.commit('TALK_STEP_CONTINUE', false)
          clearTimeout(tm)
        }, 1000)
      } else if (this.previousStep === 'Options') {
        this.$store.commit('STORY_MODE', true)
        this.$store.commit('ACTIVE_DIAGNOSE', this.previousDiagnoses[this.previousDiagnoses.length - 1].diagnose)
        this.$store.commit('ACTIVE_PANORAMA', this.previousDiagnoses[this.previousDiagnoses.length - 1].panorama)
        const tm = setTimeout(() => {
          let lastOptionMenu = this.previousDiagnoses[this.previousDiagnoses.length - 1].diagnose === 6 ? 5 : this.previousDiagnoses[this.previousDiagnoses.length - 1].diagnose
          this.$store.commit('DIALOG_OPTIONS', [true, lastOptionMenu])
          this.$store.commit('DELETE_LAST_DIAGNOSE')
          clearTimeout(tm)
        }, 1000)
      } else if (this.previousStep === 'MoveStep') {
        this.$store.commit('STORY_MODE', true)
        this.$store.commit('ACTIVE_DIAGNOSE', this.previousDiagnoses[this.previousDiagnoses.length - 1].diagnose)
        this.$store.commit('ACTIVE_PANORAMA', this.previousDiagnoses[this.previousDiagnoses.length - 1].panorama)
      }
    },
    toggleMusic () {
      this.$store.commit('TOGGLE_MUSIC')
    },
    reset () {
      this.$store.dispatch('START_OVER')
    },
    inquiry () {
      this.$store.commit('FORM_POPUP', 'inquiry')
    },
    bookPresentation () {
      this.$store.commit('FORM_POPUP', 'presentation')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

/**
 * NOTE: use for example:
 * @media #{map-get($display-breakpoints, 'sm-and-down')} { } 
 * and such, not your own hardcoded widths / variables
 */

.minimap-wrap {
  position: absolute;
  z-index: 700;
  right: 25px;
  top: 25px;
  height: 150px;
  width: 150px;

  .menu-icon {
    position: absolute;
    border-radius: 50%;
    font-size: 30px;
    padding: 9px;
    background-color: white;
    outline: none;
    color: black;

    &.volume {
      top: 0;
      right: 70px;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        top: 55px;
        right: auto;
        left: -20px;
      }
    }

    &.story {
      top: 0;
      right: -5px;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        top: 100px;
        right: auto;
        left: 0;
      }
    }

    &.reset {
      top: 70px;
      right: -5px;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        top: 125px;
        right: auto;
        left: 45px;
      }
    }

    &.inquiry {
      top: 0;
      left: 70px;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        top: -18px;
        left: auto;
        right: 45px;
      }
    }

    &.presentation {
      top: 0;
      left: -5px;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        top: 10px;
        left: auto;
        right: 0;
      }
    }

    &.configurator {
      top: 70px;
      left: -5px;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        top: 56px;
        left: auto;
        right: -20px;
      }
    }

  }

  .minimap {
    .st0{fill:#FFFFFF;}
    .st1{opacity:0.25;fill:#FFFFFF;}
    .st2{fill:#CEAB86;}
    .st3{fill:#F7F7F7;}
    .st4{fill:#8CC63E;}
    .st5{fill:#F4E7C9;}
    .st6{fill:#FF4040;}

    position: absolute;
    top: 0;
    right: 0;

    .minimap-pin {
      visibility: hidden;
      &.active {
        visibility: hidden;
      }

      @media #{map-get($display-breakpoints, 'md-and-up')} { 
        visibility: hidden;
        &.active {
          visibility: visible;
        }
      } 
    }
  }
}
</style>
