<template>
  <v-app
    id="app-main"
  >
    <v-app-bar
      absolute
      color="transparent"
      flat
      dark
      :value="!screenWelcome && !videoPopup"
      style="z-index: 600 !important;"
      data-cy-app-bar
    >
      <div class="ma-2 ml-3">
        <mesiLogoWhite
          v-if="!screenWelcome"
          class="mesi-logo"
        />
      </div>
      <v-spacer />
      <v-app-bar-nav-icon
        size
        data-cy-settings
        class="d-md-none"
        @click="toggleRightDrawer"
      >
        <v-icon large>
          mdi-menu
        </v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>
    <div
      v-if="!screenWelcome && !videoPopup && !leftDrawer && !isMobile"
      class="px-3 pb-3 d-flex flex-column left-menu rounded-r-xl"
      @click="changeDrawer(true)"
    >
      <v-icon
        class="py-7"
      >
        mdi-arrow-right
      </v-icon>
      <v-icon
        class="pb-7"
      >
        mdi-file-question
      </v-icon>
      <v-icon
        class="pb-7"
      >
        mdi-projector-screen-outline
      </v-icon>
      <v-icon
        class="pb-7"
      >
        mdi-order-bool-ascending-variant
      </v-icon>
    </div>
    <Minimap 
      v-if="!screenWelcome && !videoPopup" 
    />
    <Menu
      :open="leftDrawer"
      @change="changeDrawer"
    />
    <v-main>
      <Tutorial v-if="showTutorial" />
      <dialogWelcome />
      <dialogTalk />
      <dialogOptions />
      <deadEndPopup />
      <videoPopup v-if="!storyMode" />
      <FormPopup />
      <router-view />
    </v-main>
    <vue-cookie-accept-decline
      v-if="interfaceTexsts.cookieNotice"
      :element-id="'cookieNotice'"
      :debug="false"
      :position="'bottom-left'"
      :type="'floating'"
      :disable-decline="false"
      :transition-name="'slideFromBottom'"
      @status="cookieStatus"
      @clickedAccept="cookieClickedAccept"
      @clickedDecline="cookieClickedDecline"
    >
      <div
        slot="message"
        class="cookie-message"
      >
        {{ interfaceTexsts.cookieNotice.text }}
        <a
          class="privacy-link"
          :href="interfaceTexsts.cookieNotice.privacy_url"
          aria-label="Privacy Policy"
          rel="noopener"
          target="_blank"
        >
          {{ interfaceTexsts.cookieNotice.privacy_policy }}
        </a>.
      </div>

      <div slot="declineContent">
        {{ interfaceTexsts.cookieNotice.decline }}
      </div>

      <div slot="acceptContent">
        {{ interfaceTexsts.cookieNotice.accept }}
      </div>
    </vue-cookie-accept-decline>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import Minimap from '@/components/Minimap'
import Menu from '@/components/Menu'
import DialogWelcome from '@/components/DialogWelcome'
import DialogTalk from '@/components/DialogTalk'
import DialogOptions from '@/components/DialogOptions'
import DeadEndPopup from '@/components/DeadEndPopup'
import VideoPopup from '@/components/VideoPopup'
import FormPopup from '@/components/FormPopup'
import mesiLogoWhite from '@/assets/mesi-logo-bel.svg'
import Tutorial from '@/components/Tutorial'

export default {
  name: 'App',
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@type': 'Organization',
          '@id': 'https://www.mesimedical.com/#organization',
          name: 'MESI, Ltd.',
          url: 'https://www.mesimedical.com/',
          logo: {
            '@type': 'ImageObject',
            '@id': 'https://www.mesimedical.com/assets/MESI-logo.svg',
            url: 'https://www.mesimedical.com/assets/MESI-logo.svg',
            contentUrl: 'https://www.mesimedical.com/assets/MESI-logo.svg'
          },
          sameAs: [
            'https://www.facebook.com/MESIdoo/',
            'https://www.linkedin.com/company/mesi-si/',
            'https://twitter.com/MESImedical'
          ]
        },
        {
          '@type': 'WebSite',
          '@id': 'https://virtual-clinic.mesimedical.com/#website',
          url: 'https://virtual-clinic.mesimedical.com/',
          name: 'MESI Virtual Clinic',
          description: 'Our state-of-the-art clinic is equipped with the MESI mTABLET system. This unique healthcare solution takes patient diagnostics to a whole new level.',
          inLanguage: ['en-US', 'de-DE', 'fr-FR', 'es-ES', 'sl-SI', 'it-IT'],
          publisher: {
            '@id': 'https://www.mesimedical.com/#organization'
          },
          copyrightHolder: { '@id': 'https://www.mesimedical.com/#organization' }
        },
        {
          '@type': 'BreadcrumbList',
          '@id': 'https://virtual-clinic.mesimedical.com/#breadcrumb',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              name: 'MESI Virtual Clinic'
            }
          ]
        }
      ]
    }
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'MESI Virtual Clinic',
    htmlAttrs: {
      lang: 'en'
    },
    link: [
        { rel:'icon', type: 'image/x-icon', href: '/favicon.png' },
        { rel:'stylesheet', href: 'https://fonts.googleapis.com/css?family=Roboto:300,400,500' },
        { rel:'stylesheet', href: 'https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css' }
    ],
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { hid: 'description', name: 'description', content: 'Our state-of-the-art clinic is equipped with the MESI mTABLET system. This unique healthcare solution takes patient diagnostics to a whole new level.' },
      { name: 'http-equiv="X-UA-Compatible"', content: 'IE=edge' },
      { name: 'keywords', content: 'MESI, diagnostics, virtual, medical, clinic, virtual clinic, vr, mesimedical' },
      { property: 'twitter:card', content: 'MESI Virtual Clinic' },
      { property: 'twitter:title', content: 'MESI Virtual Clinic' },
      { property: 'twitter:description', content: 'Our state-of-the-art clinic is equipped with the MESI mTABLET system. This unique healthcare solution takes patient diagnostics to a whole new level.' },
      { property: 'twitter:image', content: 'https://virtual-clinic.mesimedical.com/start-bg.jpg' },
      { property: 'og:title', content: 'MESI Virtual Clinic' },
      { property: 'og:site_name', content: 'MESI Virtual Clinic' },
      { property: 'og:type', content: 'website' },
      { property: 'og:description', content: 'Our state-of-the-art clinic is equipped with the MESI mTABLET system. This unique healthcare solution takes patient diagnostics to a whole new level.' },
      { property: 'og:image', content: 'https://virtual-clinic.mesimedical.com/start-bg.jpg' }
    ],
    /* script: [
      { charset: 'utf-8', type: 'text/javascript', src: 'https://scripts.simpleanalyticscdn.com/latest.js', async: true },
      { charset: 'utf-8', type: 'text/javascript', src: 'https://scripts.simpleanalyticscdn.com/auto-events.js', async: true }
    ], */
    noscript: [
      { innerHTML: "We're sorry but MESI Virtual Clinic doesn't work properly without JavaScript enabled. Please enable it to continue." }
    ]
  },
  components: {
    Minimap,
    Menu,
    DialogWelcome,
    DialogTalk,
    DialogOptions,
    DeadEndPopup,
    VideoPopup,
    FormPopup,
    mesiLogoWhite,
    Tutorial
  },
  data() {
    return {
      rightDrawer: false,
      status: null
    }
  },
  computed: {
    ...mapGetters([
      'language',
      'screenWelcome',
      'interfaceTexsts',
      'videoPopup',
      'storyMode',
      'showTutorial',
      'formPopup',
      'leftDrawer'
    ]),
    statusText () {
      return this.status || 'No cookie set'
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  mounted () {
    this.$nextTick(() => {
      //HubSpot form script
      let form = document.createElement('script')

      form.setAttribute('src', '//js.hsforms.net/forms/v2.js')
      form.setAttribute('type', 'text/javascript')
      form.setAttribute('charset', 'utf-8')

      document.head.appendChild(form)
    })
  },
  methods: {
    toggleRightDrawer () {
      this.$store.commit('SHOW_RIGHT_MENU', true)
    },
    changeDrawer (newVal) {
      this.$store.commit('SHOW_LEFT_MENU', newVal)
    },
    cookieStatus (status) {
      this.status = status
    },
    cookieClickedAccept () {
      this.status = 'accept'
    },
    cookieClickedDecline () {
      this.status = 'decline'
    }
  }
}
</script>

<style lang="scss">
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  overflow: hidden;
}

.v-dialog.v-dialog--active.v-dialog--persistent.v-dialog--fullscreen:not(.dialog-welcome) {
  overflow: hidden;
  @media (max-width: 400px) {
    overflow-y: auto !important;
  }
}

.canvas.panolens-canvas {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.mesi-logo {
  .st0{fill:#FFFFFF;}
  height: 30px;
}

.left-menu {
  cursor: pointer;
  z-index: 600 !important;
  position: absolute !important;
  background-color: rgba(255, 255, 255, 0.85) !important;
  margin-top: 90px;
}

.back-btn {
  &:hover {
    .back-text {
      text-decoration: underline;
      font-weight: 800 !important;
    }
  }
}

.back-btn:before {
  background-color: transparent;
}

.no-outline:focus {
  outline: none;
}

.options-btn {
  cursor: pointer;
  .icon {
    visibility: hidden;
  }
}

.optionActive {
  background-color: rgba(255, 255, 255, 0.16);
    .icon {
      visibility: visible;
    }
}

.cookie-message {
  .privacy-link {
    color: #8CC63E;
  }
}

.cookie__floating__buttons__button {
  &.cookie__floating__buttons__button--accept {
    color: #8CC63E;
    background-color: #FFFFFF;
    &:hover {
      color: #FFFFFF;
      background-color: #8CC63E;
    }
  }
  &.cookie__floating__buttons__button--decline {
    color: #EA2227;
    background-color: #FFFFFF;
    &:hover {
      color: #FFFFFF;
      background-color: #EA2227;
    }
  }
}

/* Below two classes are for Google Analytics click listeners */

// Use this for v-btn or button tags
.pointer-events-for-analytics {
  .v-btn__content {
    pointer-events: none;
  }
}

// Use this for other clickable elements (v-row, div, etc.)
.options-btn > * {
  pointer-events: none;
}

.hbspt-form {
  display: flex;
  justify-content: center;

  .hs-form fieldset {
    max-width: 100%;
    width: 100%;
  }

  h2 {
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 2.5rem;
  }

  .hs-form-required {
    color: #ff0000;
  }

  .hs-error-msgs {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .hs-error-msg {
      color: #f2545b;
      font-size: 14px;
    }
  }

  .hs-form-field .input {
    font-size: 16px;
    justify-content: center;
    margin-top: 10px;
  }

  .hs-form-checkbox-display, .hs-form-booleancheckbox-display {
    .hs-input {
      width: auto !important;
    }
  }

  .hs-fieldtype-checkbox {
    font-size: 18px;
    text-align: center;
  }

  .hs-input {
    background-color: #f8f8f8;
    display: inline-block;
    width: 100% !important;
    height: 40px;
    padding: 9px 10px;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    box-sizing: border-box;
    border-radius: 1.5rem;
    border: 1px solid #f1f1f1;

    &:focus-visible {
      outline: none;
    }
  }
  .field {
    margin-bottom: 18px;
  }
  .hs-form * {
    box-sizing: border-box;
  }
  fieldset.form-columns-2 .hs-form-field {
    width: 47.5%;
    float: left;
  }
  .hs-custom-style>div>div:last-of-type .hs-input:not([type=checkbox]):not([type=radio]), .hs-custom-style fieldset>div:last-of-type .hs-input:not([type=checkbox]):not([type=radio]) {
    width: 100%;
    max-width: 100%;
  }
  .hs-custom-style>div textarea, .hs-custom-style fieldset textarea {
    padding: 10px 15px;
  }

  .input {
    display: flex;

    select {
      cursor: pointer;

      &:focus-visible {
        outline: none;
        outline-offset: 0px;
      }
      option {
        color: black;
        cursor: pointer;
      }
      &.is-placeholder {
        color: #bfbfbf;
      }
      &:not(:-internal-list-box) {
        background-color: #000;
        overflow: visible !important;
      }
    }
    textarea.hs-input {
      height: auto;
    }
    textarea {
      writing-mode: horizontal-tb !important;
      font-family: monospace;
      text-rendering: auto;
      color: fieldtext;
      letter-spacing: normal;
      word-spacing: normal;
      line-height: normal;
      text-transform: none;
      text-indent: 0px;
      text-shadow: none;
      display: inline-block;
      text-align: start;
      appearance: auto;
      -webkit-rtl-ordering: logical;
      resize: auto;
      cursor: text;
      white-space: pre-wrap;
      overflow-wrap: break-word;
      background-color: field;
      column-count: initial !important;
      box-sizing: border-box;
      width: 100% !important;
      margin: 0em;
      padding: 10px 15px;
      border-radius: 1.5rem;
      background-color: #f8f8f8;
      border: 1px solid #f1f1f1;
      background-color: rgb(248, 248, 248) !important;

      &:focus-visible {
        outline: none;
      }
    }
  }
  .hs-richtext {
    font-size: 12px;
    margin: 10px 0;

    p a {
      color: #8cc63e;

      :hover {
        text-decoration: underline;
      }
    }
  }
  .hs-button {
    color: #fff;
    background-color: #8cc63e;
    padding: 0.5rem 2rem;
    border-radius: 1.5rem;
    font-weight: 500;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 50px;
    cursor: pointer;

    &:hover {
      opacity: 90%;
    }

    &.primary, .large {
      background-color: #8cc63e !important;
    }
  }
  .inputs-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    justify-self: left;

    .hs-form-booleancheckbox, .hs-form-checkbox {
      font-size: 14px;
      font-weight: 200;

      .hs-form-booleancheckbox-display, .hs-form-checkbox-display {
        float: none;
        width: auto;
        padding: 0;
        line-height: 18px;
        white-space: normal;
        font-weight: normal;

        .hs-input {
          cursor: pointer;
          width: auto;
          height: auto;
          padding: 0;
          margin: 3px 5px 3px 0px;
          line-height: normal;
          border: none;
          max-width: 100%;
        }
      }
    }
  }
}

</style>
