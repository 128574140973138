<template>
  <v-navigation-drawer
    v-if="interfaceTexsts.menuLeft"
    absolute
    :temporary="false"
    :hide-overlay="true"
    :value="leftDrawer && !isMobile"
    height="auto"
    width="auto"
    class="rounded-r-xl left-menu"
    color="rgba(255, 255, 255, 0.85)"
    stateless
    @input="changeDrawerStatus"
  >
    <div class="d-flex">
      <v-btn
        icon
        class="ml-auto mt-6 mr-8"
        aria-label="close menu"
        data-cy-close-menu
        @click="changeDrawerStatus(false)"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </div>

    <v-list
      data-cy-menu-list
      class="pt-0 pb-5 mr-5"
    >
      <v-list-item
        :id="'inquiry-left-menu-'+language"
        class="analytics-event options-btn"
        link
        target="_blank"
        rel="noopener"
        aria-label="inquiry"
        @click="inquiry"
      >
        <v-list-item-icon class="mr-3">
          <v-icon>mdi-file-question</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ interfaceTexsts.menuLeft.send_inquiry }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :id="'book-presentation-left-menu-'+language"
        class="analytics-event options-btn"
        link
        target="_blank"
        rel="noopener"
        aria-label="book presentation"
        @click="bookPresentation"
      >
        <v-list-item-icon class="mr-3">
          <v-icon>mdi-projector-screen-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ interfaceTexsts.menuLeft.book_presentation }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :id="'configurator-left-menu-'+language"
        class="analytics-event options-btn"
        link
        target="_blank"
        rel="noopener"
        aria-label="open configurator"
        href="https://configurator.mesimedical.com"
      >
        <v-list-item-icon class="mr-3">
          <v-icon>mdi-order-bool-ascending-variant</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ interfaceTexsts.menuLeft.configurator }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import mixins from '@/mixins.js'

export default {
  mixins: [mixins],
  computed: {
    ...mapGetters([
      'leftDrawer',
      'interfaceTexsts',
      'language',
    ]),
    statusText () {
      return this.status || 'No cookie set'
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    changeDrawerStatus (newVal) {
      this.$store.commit('SHOW_LEFT_MENU', newVal)
    },
    inquiry () {
      this.$store.commit('FORM_POPUP', 'inquiry')
    },
    bookPresentation () {
      this.$store.commit('FORM_POPUP', 'presentation')
    }
  }
}
</script>
